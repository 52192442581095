import { useSuspenseQueries } from "@tanstack/react-query";
import { useContractId } from "./useContractId";
import { dataContracts } from "../data/dataContracts";
import { queryClient } from "../App";

export function useContract() {
  const contractId = useContractId();
  const [{ data: contract }] = useSuspenseQueries({
    queries: [dataContracts.fetchContract(contractId)],
  });

  return contract;
}

export function useInvalidateContract() {
  const contractId = useContractId();
  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: dataContracts.getContractKey(contractId),
      refetchType: "all",
    });
  };

  return invalidate;
}

export function useAsyncInvalidateContract() {
  const contractId = useContractId();
  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: dataContracts.getContractKey(contractId),
      refetchType: "all",
    });
  };

  return invalidate;
}
