import { Associate, AssociateRole } from "../data/models/AssociateTypes";
import { AssociateId, Cas, DEFAULT_CAS } from "../data/models/CommonTypes";
import { Country } from "../i18n";

const DUMMY_OWNER_ID = "aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" as AssociateId;

export function isOwner(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1;
}

export function isSignee(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.SELECTED_SIGNATORY) > -1;
}

export function isPrimary(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1;
}

export function isAccountHolder(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1;
}

export function getPrimary(associates: Associate[] = []) {
  return associates.find((associate) => isPrimary(associate));
}

export const defaultAssociate: Associate = {
  associateId: DUMMY_OWNER_ID,
  cas: DEFAULT_CAS as Cas,
  roles: [],
  address: {
    city: "",
    street: "",
    postalCode: "",
    countryCode: Country.SWEDEN,
  },
  contact: {},
  id: {},
  pep: {},
};
