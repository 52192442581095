import { data, NOT_FOUND_STATUS_CODE } from "./data";
import { ContractId } from "./models/CommonTypes";
import { BankAccount } from "./models/ContractTypes";
import { API, ServerError } from "../network/API";

const BASE_PATH = "/api/sales";

export interface LocationSuggestionResult {
  text: string;
}

// Delete and change?
// POST /api/sale/{contractId}/bank/reject
// {
//   firstName: string,
//   lastName: string,
//   email: string,
//   phoneNumber,
// }

export const dataBank = {
  fetchBank: (contractId: ContractId) => ({
    queryKey: dataBank.getBankAccountKey(contractId),
    queryFn: () => dataBank.getBankAccount(contractId),
  }),

  getBankAccount: (contractId: ContractId) =>
    data.get<BankAccount>(`${BASE_PATH}/${contractId}/bank`).catch((err) => {
      if (err && (err as ServerError<any>).status === NOT_FOUND_STATUS_CODE) {
        return Promise.resolve({} as BankAccount);
      }

      if (err && (err as ServerError<any>).status === 500) {
        return Promise.resolve({} as BankAccount);
      }

      console.error(err.stack);
      throw err;
    }),

  getBankAccountKey: (contractId: ContractId) => {
    return ["bank-account", contractId];
  },

  verifyAccountByBankgiro: (contractId: ContractId, bban: string) =>
    data.post(`${BASE_PATH}/${contractId}/bank/sweden/update`, {
      bban,
    }),

  rejectBankAccount: (contractId: ContractId) =>
    data.delete(`${BASE_PATH}/${contractId}/bank`),

  addBankAccount: (contractId: ContractId, bankAccount: BankAccount) =>
    data.post(`${BASE_PATH}/${contractId}/bank/confirm`, bankAccount),

  getBankAccountStatementUrl: (contractId: ContractId) =>
    API.getUrl(`${BASE_PATH}/${contractId}/bank/document`),

  notifyRejection: (contractId: ContractId) =>
    data.delete(`${BASE_PATH}/${contractId}/bank`),

  remindAccountHolder: (contractId: ContractId) =>
    data.post(`${BASE_PATH}/${contractId}/bank/remind`),
};
