import React, { useState, useCallback, useRef, useEffect } from "react";
import cx from "classnames";
import styles from "./Notes.module.scss";
import { FormName } from "../menus/ContractEditMenu";
import { useAtom } from "jotai";
import { Checkboxes } from "../../../../components/interactions/Checkboxes/Checkboxes";
import { useTranslation } from "react-i18next";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { Form } from "../../../../components/form/Form";
import { TextArea } from "../../../../components/form/TextArea";
import { T } from "../../../../components/translation/T";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../data/types";
import { useContract } from "../../../../hooks/useContract";
import { notesState } from "../../../../state/notesState";

interface Props {
  disabled?: boolean;
}

export const Notes: React.FunctionComponent<Props> = ({ disabled }) => {
  const contract = useContract();
  const { t } = useTranslation();
  const [notes, setNotes] = useAtom(notesState);
  const id = useRef("text_" + Math.random().toString(36).substring(2, 9));
  const [prevMessage, setPrevMessage] = useState<string>();
  const [addNotes, setAddNotes] = useState<boolean>(!!notes.notes);

  useEffect(() => {
    if (!notes && contract.contractData.notes) {
      setNotes({ notes: contract.contractData.notes });
    }
  }, [notes, setNotes, contract.contractData.notes]);

  const onChange = useCallback(
    (value: string) => {
      setPrevMessage("");
      setNotes(() => ({ notes: value }));
    },
    [setNotes]
  );

  return (
    <div className={styles.notes}>
      <Checkboxes
        className={cx(styles.toggle)}
        name={id.current}
        label={t("Add notes to downstream systems")}
        alternatives={[
          {
            value: "yes",
            text: t("Yes"),
          },
        ]}
        values={addNotes ? ["yes"] : []}
        onChange={() => {
          if (addNotes) {
            setPrevMessage(notes.notes);
          }
          setAddNotes((prev) => !prev);
          setNotes(() => ({ notes: "" }));
        }}
        disabled={disabled}
      />

      <Form name={FormName.NOTES}>
        <AnimateHeight name={addNotes ? "one" : "two"}>
          <div>
            {addNotes ? (
              <div className={styles.innerNotes}>
                <TextArea
                  label="Add notes"
                  onChange={onChange}
                  value={notes.notes}
                  validators={[]}
                  disabled={disabled}
                />

                <Button
                  className={cx("as-link", "small", styles.button, {
                    [styles.active]: !notes.notes && prevMessage,
                  })}
                  status={
                    !disabled && !notes.notes && prevMessage
                      ? Status.DEFAULT
                      : Status.DISABLED
                  }
                  onClick={() => setNotes(() => ({ notes: prevMessage }))}
                >
                  <T>Latest saved</T>
                </Button>
              </div>
            ) : null}
          </div>
        </AnimateHeight>
      </Form>
    </div>
  );
};
