import { LegalEntityType } from "./dataCompany";
import { Country } from "../i18n";
import { data } from "./data";
import { Associate } from "./models/AssociateTypes";
import {
  AssociateId,
  Cas,
  ContractId,
  CorrelationId,
  StoreId,
  UTCDate,
} from "./models/CommonTypes";
import {
  CompletedContract,
  Contract,
  ContractStatus,
  MainContractData,
  MccRiskLevel,
  MerchantCategory,
  Notes,
  ProductType,
  Store,
} from "./models/ContractTypes";

export interface LookupContractResponse {
  contractId?: ContractId;
  status: LookupStatus;
}

export enum LookupStatus {
  NEW = "NEW",
  SALES = "SALES",
  MERCHANT = "MERCHANT",
  COMPLETE = "COMPLETE",
}

export interface SaveContractResponse {
  cas: Cas;
}

export interface ListContract {
  contractId: ContractId;
  salesUser: string;
  salesforceUrl?: string;
  companyName: string;
  status: ContractStatus;
  legalEntityType: LegalEntityType;
  productType: ProductType;
  country: Country;
  organizationNumber: string;
  created: UTCDate;
}

export interface ExistingMerchantData {
  created: UTCDate;
  mcc: number;
  mid: string;
  iban: string;
}

export interface MCC {
  code: string;
  label: string;
  description: string;
  tag: string;
  salesCategory: string;
  risk: string;
  cp: MccRiskLevel;
  cnp: MccRiskLevel;
}

export interface CreateContractResponse {
  contractId: ContractId;
  existingMerchantData?: ExistingMerchantData;
}

export const CAS_ERROR_CODE = 409;
export const CLAIM_ERROR_CODE = 423;
export const SERVER_ERROR_CODE = 500;
const BASE_URL = "/api/sales";

export function isEcom(contract: Contract) {
  return (
    contract.productType === ProductType.ACCEPTANCE_ONLINE ||
    contract.productType === ProductType.CHECKOUT
  );
}

export const dataContracts = {
  companyLookup: (correlationId: CorrelationId) =>
    data.get<LookupContractResponse>(
      `/api/sales/contract/status?correlationId=${correlationId}`
    ),

  fetchCompanyLookup: (correlationId: CorrelationId) => ({
    queryKey: ["contracts", "company", correlationId],
    queryFn: () => dataContracts.companyLookup(correlationId),
  }),

  createContract: (correlationId: CorrelationId) =>
    data.post<CreateContractResponse>(
      `/api/sales/contract/new?correlationId=${correlationId}`
    ),

  fetchContracts: () => ({
    queryKey: ["contracts"],
    queryFn: () => dataContracts.getContracts(),
  }),

  getContracts: () => data.get<ListContract[]>(`${BASE_URL}/contracts`),

  loadContract: (contractId: ContractId) =>
    data.get<Contract>(`${BASE_URL}/${contractId}`),

  fetchContract: (contractId: ContractId) => ({
    queryKey: dataContracts.getContractKey(contractId),
    queryFn: () => data.get<Contract>(`${BASE_URL}/${contractId}`),
  }),

  getContractKey: (contractId: ContractId) => {
    return ["contracts", contractId];
  },

  deleteContract: (contractId: ContractId) =>
    data.delete(`/api/sales/contracts/${contractId}`),

  //Kastar en 423 LOCKED om man inte har claimat
  saveMainContractData: (contractId: ContractId, request: MainContractData) =>
    data.post<SaveContractResponse>(
      `/api/sales/contracts/${contractId}`,
      request
    ),

  //Den här kommer att cachea med en etag sen
  loadMcc: () => data.get<MerchantCategory[]>(`/api/sales/mcc`),

  loadMccCodes: () => {
    const { origin } = new URL(window.location.href);
    return data.get<MCC[]>(`${origin}/libs/mcc.json`);
  },

  saveStore: (contractId: ContractId, request: Store, storeId?: StoreId) => {
    const uri =
      `/api/sales/contracts/${contractId}/store` +
      (storeId ? `/${storeId}` : ``);

    return data.post<Store[]>(uri, request);
  },

  getAssociate: (
    contractId: ContractId,
    associateId: AssociateId,
    force = false
  ) =>
    data.get<Associate>(
      `/api/sales/contracts/${contractId}/associates/${associateId}`
    ),

  flagAsSignee: (
    contractId: ContractId,
    associateId: AssociateId,
    request: {
      title?: string;
      email?: string;
      number?: string;
    }
  ) =>
    data.post<Associate>(
      `/api/sales/contracts/${contractId}/associates/${associateId}/signer`,
      request
    ),

  loadCompletedContract: (contractId: ContractId) =>
    data.get<CompletedContract>(`/api/sales/contracts/completed/${contractId}`),

  // addSalesCustomDocument: (contractId: ContractId, description: string) => {
  //   return data.post<ContractDocument[]>(
  //     `/api/sales/contracts/${contractId}/document/custom`,
  //     {
  //       documentDescription: description,
  //     }
  //   );
  // },

  resendMerchantEmail: (contractId: ContractId) => {
    return data.post(
      `/api/sales/contracts/completed/${contractId}/email/merchant`
    );
  },

  resendBackofficeEmail: (contractId: ContractId) => {
    return data.post(
      `/api/sales/contracts/completed/${contractId}/email/backoffice`
    );
  },

  finalizeAndSendForConfirmation: (contractId: ContractId, notes?: Notes) =>
    data.post(`/api/sales/${contractId}/confirmation`, notes),
};
