import React from "react";
import { T } from "../../../../components/translation/T";
import { ProductCard } from "../offering/products/ProductCard";
import { PRODUCT_OFFERINGS } from "../offering/products/Products";
import { useContract } from "../../../../hooks/useContract";

export const ConfirmProduct: React.FunctionComponent = () => {
  const contract = useContract();

  return (
    <div>
      <b>
        <T>Product</T>
      </b>
      <ProductCard isSelected {...PRODUCT_OFFERINGS[contract.productType]} />
    </div>
  );
};
