import { useCallback, useEffect, useMemo, useState } from "react";
import { DefaultBox } from "../../../components/boxes/DefaultBox";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../../components/boxes/StatusBoxes";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import {
  ConfirmButton,
  ConfirmType,
} from "../../../components/interactions/Buttons/ConfirmButton";
import { Visible } from "../../../components/visible/Visible";
import { dataMerchant } from "../../../data/dataMerchant";
import {
  BankAccount,
  Contract,
  ContractDocument,
  ContractStatus,
  DocumentType,
  EcomStore,
} from "../../../data/models/ContractTypes";
import { Status } from "../../../data/types";
import { Timeline } from "./Timeline/Timeline";
import { T } from "../../../components/translation/T";
import {
  dataAssociates,
  filterSelectedSignees,
} from "../../../data/dataAssociates";
import { useContractId } from "../../../hooks/useContractId";
import { dataDocuments } from "../../../data/dataDocuments";
import { dataBank } from "../../../data/dataBank";
import { Actions } from "./Actions/Actions";
import { useSuspenseQueries } from "@tanstack/react-query";
import { dataStores } from "../../../data/dataStores";
import { dataContracts, isEcom } from "../../../data/dataContracts";
import { InfoBox } from "../../../components/boxes/InfoBox";
import { getPrimary } from "../../../components/associateUtils";
import "./Review.scss";

// APP screenshot upload `/api/merchant/${linkId}/ecomstore/appscreen`

export function isDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}

function removeSignedContract(documents: ContractDocument[]) {
  return documents.filter(
    (documents) => documents.documentType !== DocumentType.SIGNED_CONTRACT
  );
}

function getEditStatus(contract: Contract, status: Status) {
  if (contract.readOnly) {
    return Status.DISABLED;
  }

  if (contract.status !== ContractStatus.MERCHANT_SIGNING) {
    return Status.DISABLED;
  }

  return status;
}

export function Review() {
  const contractId = useContractId();
  const [reminderStatus, setReminderStatus] = useState<Status>(Status.DEFAULT);
  const [ecomStore, setEcomStore] = useState<EcomStore>();

  const [
    { data: contract },
    { data: reviewStatus },
    { data: associates },
    { data: documents },
    { data: bank },
  ] = useSuspenseQueries({
    queries: [
      dataContracts.fetchContract(contractId),
      dataMerchant.fetchReviewStatus(contractId),
      dataAssociates.fetchAssociates(contractId),
      dataDocuments.fetchDocuments(contractId),
      dataBank.fetchBank(contractId),
    ],
  });

  const [bankAccount, setBankAccount] = useState<BankAccount | undefined>(bank);

  const signees = useMemo(
    () => filterSelectedSignees(associates),
    [associates]
  );

  const docs = useMemo(() => removeSignedContract(documents), [documents]);
  const primary = useMemo(() => getPrimary(associates), [associates]);

  useEffect(() => {
    if (!isEcom(contract)) {
      return;
    }

    dataStores
      .getEcomStore(contract.contractId)
      .then((resp) => setEcomStore(resp))
      .catch((err) => console.log("err", err));
  }, [contract, setBankAccount]);

  const sendReminders = useCallback(() => {
    dataMerchant
      .sendReminders(contract.contractId)
      .then(() => {
        setReminderStatus(Status.SUCCESS);
      })
      .catch(() => {
        setReminderStatus(Status.ERROR);

        setTimeout(() => {
          setReminderStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [contract.contractId]);

  if (contract.status === ContractStatus.SALES_INPUT) {
    return (
      <>
        <h1>{contract.contractData.companyName}</h1>
        <p className="empty-timeline">
          <T>
            This contract has not yet been sent to merchant for confirmation
          </T>
        </p>
      </>
    );
  }

  return (
    <>
      <h1>{contract.contractData.companyName}</h1>

      {!!signees.length ? null : (
        <div className="no-signee-info">
          <InfoBox relative>
            <T>
              We didn't find any signees attached to the application. They have
              probably not yet been named by the primary contact. Once named we
              will add them to the Timeline.
            </T>
          </InfoBox>
        </div>
      )}

      <div className="merchant-status">
        <div className="merchant-status-timeline">
          <Timeline
            contract={contract}
            reviewStatus={reviewStatus}
            associates={associates}
            documents={docs}
            contractId={contractId}
            bankAccount={bankAccount}
            ecomStore={ecomStore}
            primary={primary}
          />
        </div>

        <div className="merchant-status-actions">
          <Actions
            associates={associates}
            bankAccount={bankAccount}
            documents={docs}
          />

          <div className="reminder-box m-top-40">
            <Visible
              show={
                /* !allConfirmed Oklart om vi behöver ha den här, det går ut automatiska och man kan trigga individuella reminders */
                false
              }
            >
              <StatusBoxes status={reminderStatus}>
                <ErrorBox>
                  <div>
                    <b>
                      <T>Ouch!</T>
                    </b>{" "}
                    <T>
                      Something went wrong. We couldn't send the reminders. Try
                      again?
                    </T>
                  </div>
                </ErrorBox>

                <SuccessBox>
                  <div>
                    <T>Reminders have been sent!</T>
                  </div>
                </SuccessBox>

                <DefaultBox relative>
                  <T>
                    Note that not all signatories have signed the contract. You
                    can send reminders to those that haven't uploaded their
                    signature.
                  </T>
                </DefaultBox>
              </StatusBoxes>

              <ConfirmButton
                confirmType={ConfirmType.SUCCESS}
                ghost
                onClick={sendReminders}
                status={getEditStatus(contract, reminderStatus)}
                removeButtonText="Yes, send"
              >
                <T>Send reminders</T>
              </ConfirmButton>
            </Visible>
          </div>
        </div>
      </div>
    </>
  );
}
