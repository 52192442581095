import { T } from "../../../components/translation/T";
import { TSwitch } from "../../../components/translation/TSwitch";
import { ContractStatus } from "../../../data/models/ContractTypes";
import { useContract } from "../../../hooks/useContract";

export function DeleteContract() {
  const contract = useContract();

  if (contract.status === ContractStatus.COMPLETE) {
    return null;
  }

  return (
    <div className="delete-contract">
      <div className="delete-warning">
        <strong>
          <T>Delete contract</T>
        </strong>
        <p>
          <TSwitch>
            <T.Condition
              condition={contract.status === ContractStatus.SALES_CONFIRMATION}
            >
              This contract is almost completed. All that is left is to confirm
              the files/information that where attached by the merchant. Make
              sure you know what you are doing. If you still want to delete this
              contract you must do that inside Salesforce
            </T.Condition>
            <T.Default>
              If you want to delete this contract it will have to be done from
              within Salesforce.
            </T.Default>
          </TSwitch>
        </p>
      </div>
    </div>
  );
}
