import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAtomValue, useSetAtom } from "jotai";
import cx from "classnames";
import { DEFAULT_TRANSLATION_NAMESPACE } from "../../i18n";
import { TOptions } from "i18next";
import {
  RegisteredTranslationsSelector,
  translationManagementState,
  translationStateActiveSelector,
  translationStateSelector,
} from "./translationState";
import "./T.scss";

interface PropsWithChildren {
  children: string;
  options?: TOptions<{ [key: string]: any }> | undefined;
  alternativeIds?: string[];
}

interface PropsWithId {
  id: string;
  options?: TOptions<{ [key: string]: any }> | undefined;
  alternativeIds?: string[];
}

type Props = PropsWithChildren | PropsWithId;

export type TDefaultProps = Props;

export type TConditionalProps = Props & {
  condition: boolean;
};

export const T: React.FunctionComponent<Props> & {
  Condition: React.FunctionComponent<TConditionalProps>;
  Default: React.FunctionComponent<TDefaultProps>;
} = ({ ...props }) => {
  const translationModeActive = useAtomValue(translationStateActiveSelector);

  const translation = "id" in props ? props.id : props.children;

  if (typeof translation !== "string") {
    throw Error("T: child must be a string");
  }

  return translationModeActive ? (
    <TManaged {...props}>{translation}</TManaged>
  ) : (
    <TUnmanaged {...props}>{translation}</TUnmanaged>
  );
};

const TUnmanaged: React.FunctionComponent<PropsWithChildren> = ({
  children,
  options,
}) => {
  const { t } = useTranslation();

  return <>{t(children, options)}</>;
};

const TManaged: React.FunctionComponent<PropsWithChildren> = ({
  children,
  options,
  ...props
}) => {
  const { t } = useTranslation();
  const registerTranslation = useSetAtom(RegisteredTranslationsSelector);
  const setTranslation = useSetAtom(translationStateSelector);
  const ref = useRef<HTMLSpanElement | null>(null);
  const { translations } = useAtomValue(translationManagementState);

  const highlight = useCallback(() => {
    const internalRef = ref.current;
    if (internalRef) {
      internalRef.classList.add("highlight");

      setTimeout(() => {
        internalRef.classList.remove("highlight");
      }, 2000);
    }
  }, []);

  useEffect(() => {
    registerTranslation([{ id: children, ref, highlightFunction: highlight }]);
  }, [registerTranslation, children, highlight]);

  const isTranslated = useMemo(
    () =>
      !!translations?.find(
        (t) =>
          t.key === children &&
          t.namespace === (options?.namespace || DEFAULT_TRANSLATION_NAMESPACE)
      )?.value,
    [translations, children, options]
  );

  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setTranslation({ selected: children, alternatives: props.alternativeIds });
  };

  return (
    <span
      ref={ref}
      className={cx("managed-translation", { "is-missing": !isTranslated })}
      onClick={handleOnClick}
    >
      {t(children, options)}
      {props.alternativeIds && props.alternativeIds.length > 1 && (
        <span className="editable-translation-variants">
          + {props.alternativeIds.length - 1}
        </span>
      )}
    </span>
  );
};

export const TCondition: React.FunctionComponent<TConditionalProps> = ({
  ...props
}) => {
  return <T {...props} />;
};

export const TDefault: React.FunctionComponent<TDefaultProps> = ({
  ...props
}) => {
  return <T {...props} />;
};

T.Condition = TCondition;
T.Default = TDefault;

export const createTranslation = (value?: string | React.ReactNode) => {
  if (typeof value === "undefined") {
    return value;
  }

  if (typeof value === "string") {
    return <T>{value}</T>;
  }

  return value;
};

export const createTranslationOrEmpty = (value?: string | React.ReactNode) => {
  if (!value) {
    return <div className="invisible">empty</div>;
  }
  return createTranslation(value);
};
