import { useMemo } from "react";
import { Status } from "../data/types";
import { useContract } from "./useContract";

export const useContractEditStatus = (status = Status.DEFAULT): Status => {
  const { readOnly } = useContract();

  return useMemo(
    () => (readOnly ? Status.DISABLED : status),
    [readOnly, status]
  );
};
