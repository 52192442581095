import React, { useState, useCallback, useMemo } from "react";
import { HighlightBox } from "../../../../components/boxes/HighlightBox";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { T } from "../../../../components/translation/T";
import { Supplements } from "./Supplements/Supplements";
import { useAtomValue } from "jotai";
import {
  BankAccount,
  BankAccountSource,
  ContractDocument,
  ContractStatus,
} from "../../../../data/models/ContractTypes";
import { Status } from "../../../../data/types";
import { AccountHolder } from "./AccountHolder/AccountHolder";
import {
  Associate,
  AssociateRole,
} from "../../../../data/models/AssociateTypes";
import { useContractId } from "../../../../hooks/useContractId";
import { Trans, useTranslation } from "react-i18next";
import { dataMerchant } from "../../../../data/dataMerchant";
import { SuccessBox } from "../../../../components/boxes/SuccessBox";
import { Link } from "../../../../components/links/Link";
import { ConfirmedContract, STORE_KEY, Store } from "../../../../Store";
import { getIntlDate } from "../../../../components/utils";
import { Notes } from "../../Contract/notes/Notes";
import { NewOverlay } from "../../../../components/overlay/NewOverlay";
import { useContract } from "../../../../hooks/useContract";
import { notesState } from "../../../../state/notesState";

interface Props {
  bankAccount?: BankAccount;
  documents: ContractDocument[];
  associates: Associate[];
}

function getBankAccountStatus(bankAccount?: BankAccount) {
  if (!bankAccount) {
    return Status.DISABLED;
  }

  if (bankAccount.signedOff) {
    return Status.DISABLED;
  }

  // If we know that the source is a Bankgiro, we also know
  // that we are awaiting Bankgiro validation or that we
  // need to reject the bank account
  if (bankAccount.source === BankAccountSource.BANKGIRO) {
    return Status.DISABLED;
  }

  // If we know that the source is a bank statement, we also know
  // that the file has been uploaded
  if (bankAccount.source === BankAccountSource.BANK_STATEMENT) {
    return Status.DISABLED;
  }

  return Status.DEFAULT;
}

export const Actions: React.FunctionComponent<Props> = ({
  bankAccount,
  documents,
  associates,
}) => {
  const contractId = useContractId();
  const contract = useContract();
  const notes = useAtomValue(notesState);
  const { t } = useTranslation();
  const [openSupplements, setOpenSupplements] = useState<boolean>(false);
  const [openAccountHolder, setOpenAccountHolder] = useState<boolean>(false);
  const addSupplement = useCallback(() => setOpenSupplements(true), []);
  const addAccountHolder = useCallback(() => setOpenAccountHolder(true), []);
  const confirmedContracts: ConfirmedContract[] = Store.getValue(
    STORE_KEY.STORE_CONFIRMED_CONTRACTS
  );
  const [open, setOpen] = useState<boolean>(false);

  const foundConfirmedDate = confirmedContracts.find(
    (confirmedContract) => confirmedContract.contractId === contractId
  );

  const merchantSigningState =
    contract.status === ContractStatus.MERCHANT_SIGNING;

  const allSignatoriesSigned = useMemo(() => {
    const signees = associates.filter((associate) =>
      associate.roles.includes(AssociateRole.SELECTED_SIGNATORY)
    );

    if (signees.length === 0) {
      return false;
    }

    return signees.every((associate) => !!associate.signatory?.signed);
  }, [associates]);

  const allDocumentsVerified = useMemo(
    () => documents.every((doc) => !!doc.confirmed),
    [documents]
  );

  const readyForRiskEvaluation = useMemo(() => {
    if (!allSignatoriesSigned) {
      return false;
    }

    if (!bankAccount?.signedOff) {
      return false;
    }

    if (!allDocumentsVerified) {
      return false;
    }

    return true;
  }, [allSignatoriesSigned, bankAccount, allDocumentsVerified]);

  const alreadySent = useMemo(() => {
    if (foundConfirmedDate) {
      return true;
    }

    return (
      contract.status === ContractStatus.COMPLETE ||
      contract.status === ContractStatus.PROVISIONING ||
      contract.status === ContractStatus.RISK_CONFIRMATION
    );
  }, [contract, foundConfirmedDate]);

  const missing = useMemo(() => {
    const items: string[] = [];
    if (!allSignatoriesSigned) {
      items.push("one or more signatures");
    }

    if (!bankAccount?.signedOff) {
      items.push("a verfied bank account");
    }

    if (!allDocumentsVerified) {
      items.push("the confirmation of one or more documents");
    }

    return items;
  }, [allSignatoriesSigned, bankAccount, allDocumentsVerified]);

  const send = useCallback(() => {
    dataMerchant
      .confirmReview(contractId, notes)
      .then(() => {
        setStatus(Status.SUCCESS);
        if (confirmedContracts.length > 9) {
          confirmedContracts.shift();
        }

        confirmedContracts.push({
          contractId: contractId,
          confirmed: new Date(),
        });
        Store.setValue(STORE_KEY.STORE_CONFIRMED_CONTRACTS, confirmedContracts);
      })
      .catch(() => {
        setStatus(Status.ERROR);
        setTimeout(() => setStatus(Status.DEFAULT), 3000);
      });

    setOpen(false);
  }, [confirmedContracts, notes, contractId]);

  const onClose = useCallback(() => setOpen(false), []);

  const [status, setStatus] = useState<Status>(Status.DEFAULT);

  const elem = useMemo(() => {
    if (status === Status.SUCCESS) {
      return (
        <>
          <p>
            <T>This application is all set and is ready for risk evaluation</T>
          </p>
          <Button block onClick={() => {}} status={Status.SUCCESS}>
            <T>Send to risk evaluation</T>
          </Button>
        </>
      );
    }

    if (alreadySent) {
      return (
        <>
          <SuccessBox relative>
            <T>This application has already been sent for risk evaluation.</T>
            {foundConfirmedDate ? (
              <p>
                <T>It was sent </T>{" "}
                <i>
                  {getIntlDate(new Date(foundConfirmedDate?.confirmed), {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </i>
              </p>
            ) : null}
          </SuccessBox>
          <div className="m-top-20">
            <Button block onClick={() => {}} status={Status.DISABLED}>
              <T>Send to risk evaluation</T>
            </Button>
          </div>
        </>
      );
    }

    if (readyForRiskEvaluation) {
      return (
        <>
          <p>
            <T>This application is all set and is ready for risk evaluation</T>
          </p>
          <Button block onClick={() => setOpen(true)} status={status}>
            <T>Send to risk evaluation</T>
          </Button>
        </>
      );
    }

    return (
      <>
        <Trans t={t}>
          This application is <strong>not</strong> yet ready. We are still
          waiting for
        </Trans>

        {!!missing.length ? (
          <ul className="desc">
            {missing.map((text) => (
              <li key={text}>
                <i>
                  <T>{text}</T>
                </i>
              </li>
            ))}
          </ul>
        ) : null}
        <Button
          className="m-top-20"
          block
          onClick={() => {}}
          status={Status.DISABLED}
        >
          <T>Send to risk evaluation</T>
        </Button>
      </>
    );
  }, [
    t,
    alreadySent,
    status,
    missing,
    readyForRiskEvaluation,
    foundConfirmedDate,
  ]);

  return (
    <>
      <Supplements open={openSupplements} setOpen={setOpenSupplements} />
      <AccountHolder open={openAccountHolder} setOpen={setOpenAccountHolder} />
      {/* <Signatories open={openSignatories} setOpen={setOpenSignatories} /> */}

      <NewOverlay
        open={open}
        onClose={onClose}
        className="overlay-confirm"
        width={600}
      >
        <h3>Confirm send</h3>
        <div className="m-top-40">
          <Notes disabled={false} />
        </div>

        <Button type="button" className="m-top-30" block onClick={send}>
          <T>Yes, do it!</T>
        </Button>
      </NewOverlay>

      {elem}

      {contract.salesforceUrl ? (
        <div className="center m-top-30">
          <Link external link={contract.salesforceUrl}>
            <T>Show contract in Salesforce</T>
          </Link>{" "}
        </div>
      ) : null}

      <div className="m-top-40" />

      <HighlightBox>
        <strong>
          <T>Actions</T>
        </strong>

        <div className="m-top-30">
          <Button
            block
            onClick={addAccountHolder}
            status={
              merchantSigningState
                ? getBankAccountStatus(bankAccount)
                : Status.DISABLED
            }
          >
            <T>Change responsible for bank account</T>
          </Button>
          <div className="line-small m-top-10">
            <i>
              <T>
                Available when contract details has been confirmed and while
                we're in the signing phase.
              </T>
              <br />
              <T>
                Disabled when the bank account has been confirmed, when the bank
                statement has been uploaded or when we are waiting for the
                Bankgiro validation.
              </T>
            </i>
          </div>

          {/* {contract.contractData.legalEntityType !==
            LegalEntityType.SOLE_PROPRIETARY && (
            <>
              <hr />

              <Button
                block
                onClick={addSignatories}
                status={
                  merchantSigningState && !allSigned
                    ? Status.DEFAULT
                    : Status.DISABLED
                }
              >
                <T>Change signatories</T>
              </Button>
              <div className="line-small m-top-10">
                <i>
                  <T>
                    Available when contract details has been confirmed and while
                    we're in the signing phase.
                  </T>
                  <br />
                  <T>Disabled when all signatories have signed the contract</T>
                </i>
              </div>
            </>
          )} */}

          <hr />

          <Button
            block
            onClick={addSupplement}
            status={
              contract.status === ContractStatus.RISK_CONFIRMATION
                ? Status.DEFAULT
                : Status.DISABLED
            }
          >
            <T>Request supplement</T>
          </Button>
          <div className="line-small m-top-10">
            <i>
              <T>Available only during risk assessment</T>
            </i>
          </div>
          <div className="m-bottom-20" />
        </div>
      </HighlightBox>
    </>
  );
};
