import React from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../PdfStyles";
import { FirstCell } from "./FirstCell";
import { Cell } from "./Cell";
import { ComponentProps } from "../PdfPage";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({});
}

export function getLabels(t: any) {
  return {
    headerMultiple: t("Discounts"),
    header: t("Campaign"),
    type: t("Type"),
    name: t("Campaign name"),
    discount: t("Discount"),
    quantity: t("Quantity"),
    duration: t("Duration of discount"),
    percentage: t("Discount"),
  };
}

interface Props extends ComponentProps {
  isFirst?: boolean;
  multiple?: boolean;
}

export const Campaign: React.FunctionComponent<Props> = ({ show }) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(show),
    ...getSpecificStyles(show),
  };

  return (
    <>
      <View style={styles.sectionHeader}>
        <Text>{labels.header}</Text>
      </View>

      <View>
        <FirstCell label={labels.type} show={show}>
          Half price on terminals
        </FirstCell>
      </View>
      <View style={styles.split}>
        <View style={styles.column}>
          <FirstCell label={labels.quantity} show={show}>
            24 months
          </FirstCell>
        </View>
        <View style={styles.column}>
          <Cell label={labels.percentage} show={show}>
            100 %
          </Cell>
        </View>
      </View>

      {/* <View>
        <FirstCell label={labels.name} show={show}>
          WINTER 2024
        </FirstCell>
      </View>

      <View style={styles.split}>
        <View style={styles.column}>
          <FirstCell label={labels.discount} show={show}>
            50%
          </FirstCell>
        </View>
        <View style={styles.column}>
          <Cell label={labels.duration} show={show}>
            24 months
          </Cell>
        </View>
      </View> */}
    </>
  );
};
