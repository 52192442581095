import React from "react";
import { StyleSheet, Document, Page, View } from "@react-pdf/renderer";
import { getDefaultStyles } from "../PdfStyles";
import { Header } from "../componentParts/Header";
import { Footer } from "../componentParts/Footer";
import { Campaign as CampaignInfo } from "../componentParts/Campaign";
import { ComponentProps } from "../PdfPage";
import { Wrapper } from "../Wrapper";

function getSpecificStyles(show: boolean) {
  return StyleSheet.create({
    signee: {
      margin: "4px 0 20px 0",
    },
    info: {
      margin: "4px 0 10px 0",
    },
    sectionHeader: {
      fontWeight: 600,
      fontSize: 14,
      padding: "14px 0 30px 0",
    },
  });
}

const Doc = (props: ComponentProps) => {
  const styles = {
    ...getDefaultStyles(props.show),
    ...getSpecificStyles(props.show),
  };

  const len = props.config.numberOfCampaigns || 0;

  const signeeElems = [];
  for (let idx = 0; idx < len; idx++) {
    signeeElems.push(
      <React.Fragment key={idx}>
        <CampaignInfo {...props} multiple={len > 1} />
        <View style={styles.divider} />
      </React.Fragment>
    );
  }

  return (
    <Document
      creator="Worldline"
      producer="Worldline"
      language={props.language.toString()}
    >
      <Page size="A4" style={styles.page} wrap={false}>
        <Header {...props} />

        {signeeElems}

        <Footer {...props} />
      </Page>
    </Document>
  );
};

export const Campaign: React.FunctionComponent<ComponentProps> = (props) => {
  return (
    <Wrapper {...props} name="campaign">
      <Doc {...props} />
    </Wrapper>
  );
};
