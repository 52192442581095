import React from "react";
import {
  AssociateTracking,
  BankAccount,
  BankAccountSource,
  Contract,
  ContractDocument,
  ContractStatus,
  DocumentType,
  EcomStore,
  Validity,
} from "../../../../../../data/models/ContractTypes";
import { LegalEntityType } from "../../../../../../data/dataCompany";
import { useTranslation } from "react-i18next";
import { Link } from "../../../../../../components/links/Link";
import styles from "./MerchantViews.module.scss";
import {
  Associate,
  AssociateRole,
} from "../../../../../../data/models/AssociateTypes";
import { Beacon } from "../../../../../../components/beacon/Beacon";
import { SimpleBoundary } from "../../../../../../components/errors/SimpleBoundry";
import { Country } from "../../../../../../i18n";

interface Props {
  primaryTracking?: AssociateTracking;
  contract: Contract;
  bankAccount?: BankAccount;
  documents: ContractDocument[];
  ecomStore?: EcomStore;
  associates: Associate[];
}

interface LinkItem {
  link: string;
  name: string;
  endpoint: Endpoint;
}

const EXCLUDED_DOCS = [
  DocumentType.RECORD_OF_NOMINEE_SHAREHOLDERS,
  DocumentType.LETTER_OF_SHAREHOLDER_CUSTODIAN,
  DocumentType.CONTROL_STRUCTURE,
  DocumentType.ECOM_APPLICATION_SCREENSHOT,
  DocumentType.BANK_STATEMENT,
  DocumentType.SIGNED_CONTRACT,
];

export enum Endpoint {
  INTRO = "intro",
  FINANCIAL = "financial",
  OWNERS = "owners",
  OWNERSHIP_AND_CONTROL = "ownership-and-control",
  SIGNATORIES = "signatories",
  PEP = "pep",
  WEB = "website-information",
  APP = "app-information",
  PAYOUT_ACCOUNT = "payout-account",
  ACCOUNT_HOLDER = "account-holder",
  BANKGIRO = "bankgiro",
  KAR = "kar",
  BANK = "bank",
  EXTERNAL_PAYMENTS = "3rd-party-payments",
  DOCUMENTS = "documents",
}

export function getLink(merchantLink: string, endpoint: Endpoint) {
  return `${process.env.REACT_APP_MERCHANT_URL}/view/${window.btoa(
    merchantLink
  )}/${endpoint}`;
}

function showOwnershipAndControl(associates: Associate[]) {
  const owners = associates.filter((associate) =>
    associate.roles.includes(AssociateRole.BENEFICIAL_OWNER)
  );

  if (!owners.length) {
    return false;
  }

  const everyOwnerHasDirectOwnership = owners.every(
    (owner) => !owner.owner?.hasIndirectOwnership
  );

  if (!everyOwnerHasDirectOwnership) {
    return true;
  }

  const totalCapitalStake = owners.reduce((acc, cur) => {
    return acc + (cur.owner?.capitalStake ?? 0);
  }, 0);

  if (totalCapitalStake === 100) {
    return false;
  }

  const totalVotingRights = owners.reduce((acc, cur) => {
    return acc + (cur.owner?.votingRightsStake ?? 0);
  }, 0);

  if (totalVotingRights === 100) {
    return false;
  }

  return true;
}

function isPrimaryAccountHolder(associates: Associate[]) {
  const primary = associates.find((associate) =>
    associate.roles.includes(AssociateRole.PRIMARY_CONTACT)
  );

  return primary && primary.roles.includes(AssociateRole.ACCOUNT_HOLDER);
}

function getDefaultEndpoints(
  associates: Associate[],
  documents: ContractDocument[],
  contract: Contract,
  ecomStore?: EcomStore,
  bankAccount?: BankAccount
): Endpoint[] {
  let items = [Endpoint.INTRO, Endpoint.FINANCIAL, Endpoint.OWNERS];

  if (showOwnershipAndControl(associates)) {
    items = [...items, Endpoint.OWNERSHIP_AND_CONTROL];
  }

  items = [...items, Endpoint.SIGNATORIES, Endpoint.PEP];

  if (ecomStore) {
    if (ecomStore.web) {
      items = [...items, Endpoint.WEB];
    } else {
      items = [...items, Endpoint.APP];
    }
  }

  items = [...items, Endpoint.PAYOUT_ACCOUNT];

  if (contract.country === Country.SWEDEN) {
    // Third party payments
    if (
      bankAccount &&
      bankAccount.source === BankAccountSource.BANK_STATEMENT
    ) {
      items = [...items, Endpoint.EXTERNAL_PAYMENTS];
    } else {
      items = [...items, Endpoint.BANKGIRO];
    }
  } else if (contract.country === Country.NORWAY) {
    // Third party payments
    if (
      bankAccount &&
      bankAccount.source === BankAccountSource.BANK_STATEMENT
    ) {
      items = [...items, Endpoint.EXTERNAL_PAYMENTS];
    } else {
      items = [...items, Endpoint.KAR];
    }
  } else {
    items = [...items, Endpoint.ACCOUNT_HOLDER];
    if (isPrimaryAccountHolder(associates)) {
      items = [...items, Endpoint.BANK];
    }
  }

  const filteredDocs = documents.filter((doc) => {
    return !EXCLUDED_DOCS.includes(doc.documentType);
  });

  if (!!filteredDocs.length) {
    items = [...items, Endpoint.DOCUMENTS];
  }

  return items;
}

export function getMerchantLinks(
  contract: Contract,
  associates: Associate[],
  documents: ContractDocument[],
  tracking?: AssociateTracking,
  ecomStore?: EcomStore,
  bankAccount?: BankAccount
) {
  if (!isOkey(contract, tracking)) {
    return [];
  }

  if (isSoleTrader(contract)) {
    return getSoleTraderEndpoints(
      associates,
      documents,
      contract,
      ecomStore,
      bankAccount
    );
  }

  if (isLtd(contract)) {
    return getLtdEndpoints(
      associates,
      documents,
      contract,
      ecomStore,
      bankAccount
    );
  }

  return getLtdEndpoints(
    associates,
    documents,
    contract,
    ecomStore,
    bankAccount
  );
}

function getLtdEndpoints(
  associates: Associate[],
  documents: ContractDocument[],
  contract: Contract,
  ecomStore?: EcomStore,
  bankAccount?: BankAccount
) {
  return getDefaultEndpoints(
    associates,
    documents,
    contract,
    ecomStore,
    bankAccount
  );
}

function getSoleTraderEndpoints(
  associates: Associate[],
  documents: ContractDocument[],
  contract: Contract,
  ecomStore?: EcomStore,
  bankAccount?: BankAccount
) {
  return getDefaultEndpoints(
    associates,
    documents,
    contract,
    ecomStore,
    bankAccount
  ).filter((item) => {
    return (
      item !== Endpoint.OWNERS &&
      item !== Endpoint.OWNERSHIP_AND_CONTROL &&
      item !== Endpoint.SIGNATORIES &&
      item !== Endpoint.ACCOUNT_HOLDER
    );
  });
}

function isSoleTrader(contract: Contract): boolean {
  return (
    contract.contractData.legalEntityType === LegalEntityType.SOLE_PROPRIETARY
  );
}

function isLtd(contract: Contract): boolean {
  return contract.contractData.legalEntityType === LegalEntityType.LIMITED;
}

function getLinkItems(endpoints: Endpoint[], merchantLink: string): LinkItem[] {
  return endpoints.map((endpoint) => ({
    endpoint,
    name: endpoint.replaceAll("-", " "),
    link: getLink(merchantLink, endpoint),
  }));
}

function isOkey(contract: Contract, tracking?: AssociateTracking) {
  // if (contract.status !== ContractStatus.MERCHANT_INPUT) {
  //   return false;
  // }

  // const hasViewedContract = tracking?.trackingLog.find(
  //   (item) => item.event === CommunicationEvent.CONTRACT_VIEWED
  // );

  // if (!hasViewedContract) {
  //   return false;
  // }

  const merchantLink = tracking?.linkId;

  if (!merchantLink) {
    return false;
  }

  return true;
}

export const MerchantViews: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const {
    associates,
    primaryTracking,
    contract,
    bankAccount,
    documents,
    ecomStore,
  } = props;

  if (!isOkey(contract, primaryTracking)) {
    return null;
  }

  const merchantLink = primaryTracking?.linkId ?? "";

  const endpoints = isSoleTrader(contract)
    ? getSoleTraderEndpoints(
        associates,
        documents,
        contract,
        ecomStore,
        bankAccount
      )
    : getLtdEndpoints(associates, documents, contract, ecomStore, bankAccount);

  const linkItems = getLinkItems(endpoints, merchantLink);

  return (
    <div className={styles.links}>
      <SimpleBoundary
        fallback={
          <div>
            <Beacon className="aligned" validity={Validity.MISSING} mini />
            <i>Something went wrong</i>
            <br />
            <i>Can't show links</i>
          </div>
        }
      >
        <ul>
          {linkItems.map((item) => (
            <li className="active relative" key={item.name}>
              <LinkStatus {...props} endpoint={item.endpoint} />
              <Link external link={item.link}>
                {t(item.name)}
              </Link>
            </li>
          ))}
        </ul>
      </SimpleBoundary>
    </div>
  );
};

interface LinkStatusInterface extends Props {
  endpoint: Endpoint;
}

export const LinkStatus: React.FunctionComponent<LinkStatusInterface> = (
  props
) => {
  const {
    associates,
    primaryTracking,
    contract,
    bankAccount,
    documents,
    endpoint,
  } = props;

  const isSoleTrader =
    contract.contractData.legalEntityType === LegalEntityType.SOLE_PROPRIETARY;

  if (
    contract.status !== ContractStatus.SALES_INPUT &&
    contract.status !== ContractStatus.MERCHANT_INPUT
  ) {
    return <Beacon className="aligned" validity={Validity.VALID} mini />;
  }

  const filteredDocs = documents.filter((doc) => {
    return !EXCLUDED_DOCS.includes(doc.documentType);
  });

  if (filteredDocs.length && filteredDocs.every((doc) => doc.uploaded)) {
    return <Beacon className="aligned" validity={Validity.VALID} mini />;
  }

  if (filteredDocs.length && filteredDocs.some((doc) => doc.uploaded)) {
    if (Endpoint.DOCUMENTS === endpoint) {
      return <Beacon className="aligned" validity={Validity.PARTIAL} mini />;
    }

    return <Beacon className="aligned" validity={Validity.VALID} mini />;
  }

  const bankStatement = filteredDocs.find(
    (doc) => doc.documentType === DocumentType.BANK_STATEMENT
  );

  if (bankAccount?.source && Endpoint.DOCUMENTS !== endpoint) {
    return <Beacon className="aligned" validity={Validity.VALID} mini />;
  }

  if (bankStatement && Endpoint.DOCUMENTS !== endpoint) {
    return <Beacon className="aligned" validity={Validity.VALID} mini />;
  }

  const owners = associates.filter((associate) =>
    associate.roles.includes(AssociateRole.BENEFICIAL_OWNER)
  );

  const allOwnersHasPep =
    !!owners.length && owners.every((owner) => !!owner.pep?.type);

  if (Endpoint.INTRO === endpoint || Endpoint.FINANCIAL === endpoint) {
    if (!isSoleTrader && owners.length > 0) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    if (allOwnersHasPep) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    if (primaryTracking?.lastVisit) {
      return <Beacon className="aligned" validity={Validity.PARTIAL} mini />;
    }

    return <Beacon className="aligned" validity={Validity.MISSING} mini />;
  }

  const signees = associates.filter((associate) =>
    associate.roles.includes(AssociateRole.SELECTED_SIGNATORY)
  );

  if (
    Endpoint.OWNERS === endpoint ||
    Endpoint.OWNERSHIP_AND_CONTROL === endpoint
  ) {
    if (allOwnersHasPep) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    if (owners.length > 0) {
      return <Beacon className="aligned" validity={Validity.PARTIAL} mini />;
    }

    return <Beacon className="aligned" validity={Validity.MISSING} mini />;
  }

  if (Endpoint.SIGNATORIES === endpoint) {
    if (allOwnersHasPep) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    if (signees.length > 0) {
      return <Beacon className="aligned" validity={Validity.PARTIAL} mini />;
    }

    return <Beacon className="aligned" validity={Validity.MISSING} mini />;
  }

  if (Endpoint.PEP === endpoint) {
    if (allOwnersHasPep) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    if (!!owners.length && owners.some((owner) => !!owner.pep?.type)) {
      return <Beacon className="aligned" validity={Validity.PARTIAL} mini />;
    }

    return <Beacon className="aligned" validity={Validity.MISSING} mini />;
  }

  const accountHolder = associates.find((associate) =>
    associate.roles.includes(AssociateRole.ACCOUNT_HOLDER)
  );

  if (Endpoint.PAYOUT_ACCOUNT === endpoint) {
    if (bankAccount?.source || bankStatement) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    if (!isSoleTrader && accountHolder) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    return <Beacon className="aligned" validity={Validity.MISSING} mini />;
  }

  if (Endpoint.WEB === endpoint || Endpoint.APP === endpoint) {
    if (bankAccount?.source || bankStatement) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    if (!isSoleTrader && accountHolder) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    if (allOwnersHasPep) {
      return <Beacon className="aligned" validity={Validity.PARTIAL} mini />;
    }

    return <Beacon className="aligned" validity={Validity.MISSING} mini />;
  }

  if (Endpoint.ACCOUNT_HOLDER === endpoint) {
    if (!!bankAccount?.source) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    if (accountHolder) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    return <Beacon className="aligned" validity={Validity.MISSING} mini />;
  }

  if (Endpoint.EXTERNAL_PAYMENTS === endpoint) {
    if (
      bankAccount &&
      bankAccount.source === BankAccountSource.BANK_STATEMENT &&
      !!bankAccount.iban
    ) {
      return <Beacon className="aligned" validity={Validity.VALID} mini />;
    }

    return <Beacon className="aligned" validity={Validity.MISSING} mini />;
  }

  if (Endpoint.BANK === endpoint) {
    if (!bankAccount?.source) {
      return <Beacon className="aligned" validity={Validity.MISSING} mini />;
    }

    return <Beacon className="aligned" validity={Validity.VALID} mini />;
  }

  return <Beacon className="aligned" validity={Validity.MISSING} mini />;
};
