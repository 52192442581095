import { atom } from "jotai";
import { STORE_KEY, Store } from "../Store";

export type Auth = {
  authorized: boolean;
  referrer?: string;
  isPartner?: boolean;
};

export const contractAuthState = atom({
  authorized: true,
  permission: true,
  referrer: Store.getValue(STORE_KEY.STORE_REFERRER),
  isPartner: Store.getValue(STORE_KEY.STORE_IS_PARTNER),
});
