import React, { useMemo } from "react";
import cx from "classnames";
import { MISSING_DATE } from "../../../../../components/utils";
import { TIMELINE_ASSOCIATES } from "../Timeline";
import { Associate } from "../../../../../data/models/AssociateTypes";
import { ContractId } from "../../../../../data/models/CommonTypes";
import { T } from "../../../../../components/translation/T";
import { Link } from "../../../../../components/links/Link";
import {
  Contract,
  ContractStatus,
  ReviewStatus,
} from "../../../../../data/models/ContractTypes";
import { isSignee } from "../../../../../components/associateUtils";

interface Props {
  contract: Contract;
  confirmedByMerchant: string;
  associates: Associate[];
  contractId: ContractId;
  reviewStatus: ReviewStatus;
}

function allowContractPreview(contract: Contract) {
  const { status } = contract;

  if (status === ContractStatus.SALES_INPUT) {
    return false;
  }

  if (status === ContractStatus.MERCHANT_INPUT) {
    return false;
  }

  return true;
}

function allowFinalContractPreview(contract: Contract) {
  const { status } = contract;

  if (status === ContractStatus.SALES_INPUT) {
    return false;
  }

  if (status === ContractStatus.MERCHANT_INPUT) {
    return false;
  }

  if (status === ContractStatus.MERCHANT_SIGNING) {
    return false;
  }

  return true;
}

export const EventGAssociates: React.FunctionComponent<Props> = ({
  confirmedByMerchant,
  associates,
  contractId,
  contract,
}) => {
  const signees = useMemo(() => associates.filter(isSignee), [associates]);

  const collectedSignatures = useMemo(() => {
    return signees.filter((signee) => Boolean(signee.signatory?.signed)).length;
  }, [signees]);

  const active = confirmedByMerchant !== MISSING_DATE;

  const contractLink = useMemo(() => {
    if (!allowContractPreview(contract)) {
      return null;
    }

    if (allowFinalContractPreview(contract)) {
      return `${process.env.REACT_APP_TARGET_BACKEND}/api/sales/${contractId}/contract/signed`;
    }

    return `${process.env.REACT_APP_TARGET_BACKEND}/api/sales/${contractId}/contract`;
  }, [contract, contractId]);

  return (
    <li
      className={cx({
        active,
      })}
      style={{
        height: TIMELINE_ASSOCIATES,
      }}
    >
      <div className="event">
        {contractLink ? (
          <Link
            className="as-button small ghost event-button preview-contract-link"
            link={contractLink}
            external
          >
            {allowFinalContractPreview(contract) ? (
              <T>Show contract</T>
            ) : (
              <T>Preview contract</T>
            )}
          </Link>
        ) : null}

        <div className="timeline-date">{confirmedByMerchant}</div>
        <div>
          <b>
            <T>Sent to legal representatives</T>
          </b>

          <div className="additional-event-passive-text small">
            <div className="counter">
              <sup>{collectedSignatures}</sup>/<sub>{signees.length}</sub>
            </div>
            signatures collected
          </div>
        </div>
      </div>
    </li>
  );
};
