import React, { useCallback, useMemo } from "react";
import styles from "./TrackingAssociate.module.scss";
import {
  AssociateTracking,
  BankAccount,
  ContractStatus,
} from "../../data/models/ContractTypes";
import { Associate, AssociateRole } from "../../data/models/AssociateTypes";
import { Card } from "../card/Card";
import { TrackingRow } from "./TrackingRow";
import { getRoleAsText } from "../../data/dataAssociates";
import { useTranslation } from "react-i18next";
import { Reminder } from "../associate/Reminder";
import { useContractId } from "../../hooks/useContractId";
import { useQueryClient } from "@tanstack/react-query";
import { dataMerchant } from "../../data/dataMerchant";
import { useContract } from "../../hooks/useContract";
import { isAccountHolder, isPrimary, isSignee } from "../associateUtils";

interface Props {
  tracking: AssociateTracking;
  associate: Associate;
  bankAccount?: BankAccount;
}

function getRoles(associate: Associate, t: any) {
  const roles = associate.roles.filter(
    (role) =>
      role === AssociateRole.BENEFICIAL_OWNER ||
      role === AssociateRole.SIGNATORY ||
      role === AssociateRole.ACCOUNT_HOLDER ||
      role === AssociateRole.PRIMARY_CONTACT
  );

  if (roles.length) {
    return `- ${roles.map((role) => t(getRoleAsText(role))).join(", ")}`;
  }
  return "";
}

export const TrackingAssociate: React.FunctionComponent<Props> = ({
  tracking,
  associate,
  bankAccount,
}) => {
  const { contact } = associate;
  const { t } = useTranslation();
  const contractId = useContractId();
  const queryClient = useQueryClient();
  const contract = useContract();

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: dataMerchant.getReviewKey(contractId),
    });
  }, [contractId, queryClient]);

  const applicableForReminder = useMemo(() => {
    if (
      isPrimary(associate) &&
      contract.status === ContractStatus.MERCHANT_INPUT
    ) {
      return true;
    }

    if (
      isSignee(associate) &&
      contract.status === ContractStatus.MERCHANT_SIGNING &&
      !associate.signatory?.signed
    ) {
      return true;
    }

    if (
      isAccountHolder(associate) &&
      contract.status === ContractStatus.MERCHANT_SIGNING &&
      !bankAccount?.signedOff &&
      !bankAccount?.iban
    ) {
      return true;
    }

    return false;
  }, [contract, bankAccount, associate]);

  return (
    <div className={styles.wrapper}>
      <Card>
        <b>
          {contact.firstName} {contact.lastName}
        </b>{" "}
        {getRoles(associate, t)}
        {tracking.trackingLog.length && (
          <ul className={styles.list}>
            {tracking.trackingLog.map((log) => (
              <TrackingRow key={`${log.created}-${log.event}`} log={log} />
            ))}
          </ul>
        )}
        {applicableForReminder ? (
          <>
            <div className={styles.divider} />
            <Reminder
              associate={associate}
              contractId={contractId}
              onSent={invalidateCache}
            />
            <br />
          </>
        ) : null}
      </Card>
    </div>
  );
};
