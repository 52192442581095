import { useQueryClient, useSuspenseQueries } from "@tanstack/react-query";
import { useState } from "react";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { SectionFieldSet } from "../../../../components/sectionFieldSet/SectionFieldSet";
import { T } from "../../../../components/translation/T";
import { dataDocuments } from "../../../../data/dataDocuments";
import { DocumentId } from "../../../../data/models/CommonTypes";
import { Status } from "../../../../data/types";
import { useContractEditStatus } from "../../../../hooks/useContractEditStatus";
import { useContractId } from "../../../../hooks/useContractId";
import { LinkAnchors } from "../ContractEdit";
import { FormName } from "../menus/ContractEditMenu";
import { AddCustomDoc } from "./AddCustomDoc";
import { FileRow } from "../../../../components/fileUpload/FileRow";
import { dataContracts } from "../../../../data/dataContracts";
import { isContractDisabled } from "../../../../App";
import "./Uploads.scss";

export const Uploads: React.FunctionComponent = () => {
  return (
    <SectionFieldSet
      headerTitle={LinkAnchors.UPLOADS.name}
      formName={FormName.UPLOADS}
      sectionNumber={7}
    >
      <UploadsInner />
    </SectionFieldSet>
  );
};

function UploadsInner() {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);

  const contractId = useContractId();
  const [{ data: contract }, { data: documents }] = useSuspenseQueries({
    queries: [
      dataContracts.fetchContract(contractId),
      dataDocuments.fetchDocuments(contractId),
    ],
  });

  const queryClient = useQueryClient();
  const inputStatus = useContractEditStatus();
  const disabled = isContractDisabled(contract);

  function onRemove(docId: DocumentId) {
    dataDocuments
      .removeCustomDocument(contractId, docId)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: dataDocuments.getDocumentsKey(contractId),
        });
      })
      .catch((err) => {
        setStatus(Status.ERROR);
        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      });
  }

  return (
    <div className="uploads">
      <ScrollPositionAnchor id={LinkAnchors.UPLOADS.anchor} />

      <p>
        <strong>
          <T>Documents required by merchant</T>
        </strong>
      </p>
      {documents.map((doc) => (
        <FileRow
          key={doc.documentId}
          status={status === Status.ERROR ? status : inputStatus}
          document={doc}
          onRemove={onRemove}
          disabled={disabled}
        />
      ))}
      {documents.length === 0 && (
        <p>
          <T>
            There are no required documents associated with this application
          </T>
        </p>
      )}
      <AddCustomDoc />
    </div>
  );
}
