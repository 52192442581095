import { MotionValue } from "framer-motion";
import { atom } from "jotai";
import { Store, STORE_KEY, Theme } from "../Store";

export enum ViewState {
  STRIPPED = "stripped",
  DEFAULT = "default",
}

interface SidebarState {
  open: boolean;
  width: MotionValue<number>;
}

export const themeState = atom<Theme>(Store.getValue(STORE_KEY.STORE_THEME));

export const viewState = atom<ViewState>(ViewState.DEFAULT);

export const contractSidebarState = atom<SidebarState>({
  open: false,
  width: new MotionValue(),
});
