import React from "react";
import cx from "classnames";
import styles from "./ConfirmStores.module.scss";
import { useContractId } from "../../../../hooks/useContractId";
import { useSuspenseQuery } from "@tanstack/react-query";
import { dataStores } from "../../../../data/dataStores";
import { ErrorBoundary } from "@sentry/react";
import {
  ProductType,
  Store,
  TerminalType,
} from "../../../../data/models/ContractTypes";
import {
  contractAllowsForTerminalType,
  terminalName,
} from "../stores/terminals/Terminals";
import { useTranslation } from "react-i18next";
import { T } from "../../../../components/translation/T";
import { Flag } from "../../../../components/flags/Flag";
import { useContract } from "../../../../hooks/useContract";

interface Props {}

function getNumberOfTerminals(store: Store): Record<TerminalType, number> {
  return Object.values(TerminalType).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: store[cur]?.terminals ?? 0,
    }),
    {} as Record<TerminalType, number>
  );
}

export const ConfirmStores: React.FunctionComponent<Props> = () => {
  const contract = useContract();

  if (
    contract.productType === ProductType.ACCEPTANCE_ONLINE ||
    contract.productType === ProductType.CHECKOUT
  ) {
    return null;
  }

  return <ConfirmStoresInner />;
};

export const ConfirmStoresInner: React.FunctionComponent<Props> = () => {
  const contractId = useContractId();
  const { t } = useTranslation();
  const contract = useContract();
  const { data: stores } = useSuspenseQuery(dataStores.fetchStores(contractId));

  return (
    <div className={cx("m-top-30")}>
      <ErrorBoundary>
        <b>
          <T>Stores</T>
        </b>
        {stores.map((store) => {
          const terminals = getNumberOfTerminals(store);
          return (
            <div
              key={store.storeId}
              className={cx(styles.store, "m-bottom-20")}
            >
              <Flag
                country={store.address?.countryCode ?? ""}
                height={16}
                rounded
                shadow
              />{" "}
              &nbsp;
              <i>{store.commercialName}</i>
              <ul className="small">
                {Object.values(TerminalType).map((type) => {
                  if (!contractAllowsForTerminalType(contract, type)) {
                    return null;
                  }

                  if (terminals[type] === 0) {
                    return null;
                  }

                  return (
                    <li key={type}>
                      <span className={cx(styles.terminalName)}>
                        {terminalName[type]}
                      </span>{" "}
                      <span className={styles.terminalNo}>
                        {terminals[type]}
                      </span>{" "}
                      {t("terminals")}
                    </li>
                  );
                })}
                <li />
              </ul>
            </div>
          );
        })}
      </ErrorBoundary>
    </div>
  );
};
