import React, { useState, FunctionComponent } from "react";
import extStyles from "./PdfPage.module.scss";
import styles from "./PdfPage.module.scss";
import { useTranslation } from "react-i18next";
import { Select } from "../../../components/form/Select";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import { EcomType } from "../../../data/models/ContractTypes";
import { Language } from "../../../i18n";
import { Page } from "../../Page";
import { WorldlineOne } from "./applications/WorldlineOne";
import { RadioGroup } from "../../../components/form/RadioGroup";
import { CompanyInformation } from "./components/CompanyInformation";
import { WorldlineOneShortTerm } from "./applications/WorldlineOneShortTerm";
import { WorldlineDevice } from "./applications/WorldlineDevice";
import { WorldlineDeviceShortTerm } from "./applications/WorldlineDeviceShortTerm";
import { AcceptanceInstore } from "./applications/AcceptanceInstore";
import { AcceptanceOnline } from "./applications/AcceptanceOnline";
import { Checkout } from "./applications/Checkout";
import { Pricing } from "./components/Pricing";
import { PrintSection } from "../../../data/dataAdmin";
import { Signatory } from "./components/Signatory";
import { Disclaimer } from "./components/Disclaimer";
import { Store } from "./components/Store";
import { SalesData } from "./components/SalesData";
import { Campaign } from "./components/Campaign";

import { LanguageSelect } from "../../../components/languageSelect/LanguageSelect";
import { Number } from "../../../components/interactions/Number/Number";

export const PDF_PAGE_URL = "/pdf";

export interface ComponentProps {
  language: Language;
  productType: PdfProductType;
  show: boolean;
  config: Config;
}

export enum PdfProductType {
  ACCEPTANCE_ONLINE = "Acceptance Online",
  ACCEPTANCE_INSTORE = "Acceptance Instore",
  BAMBORA_DEVICE = "Worldline Device",
  BAMBORA_DEVICE_SHORT_TERM = "Worldline Device short term",
  BAMBORA_ONE = "Worldline One",
  BAMBORA_ONE_SHORT_TERM = "Worldline One short term",
  CHECKOUT = "Checkout",
}

export const productTypeDisplayName: Record<PdfProductType, string> = {
  [PdfProductType.ACCEPTANCE_ONLINE]: "Acquiring Online",
  [PdfProductType.ACCEPTANCE_INSTORE]: "Acquiring In-Store",
  [PdfProductType.BAMBORA_DEVICE]: "Worldline Device",
  [PdfProductType.BAMBORA_DEVICE_SHORT_TERM]: "Worldline Device",
  [PdfProductType.BAMBORA_ONE]: "Worldline One In-Store Payments",
  [PdfProductType.BAMBORA_ONE_SHORT_TERM]: "Worldline One In-Store Payments",
  [PdfProductType.CHECKOUT]: "Acceptance Online Checkout Payments",
};

interface Option {
  value: PdfProductType;
  text: string;
  component: FunctionComponent<ComponentProps> | null;
}

interface ComponentOption {
  value: PrintSection;
  text: string;
  component: FunctionComponent<ComponentProps> | null;
}

export interface Config {
  ecomType: EcomType;
  numberOfStores?: number;
  numberOfSignees?: number;
  numberOfCampaigns?: number;
  pageNumber?: string;
  numberOfPages?: string;
}

const opts: Option[] = [
  {
    value: PdfProductType.BAMBORA_ONE,
    text: "Worldline One",
    component: WorldlineOne as FunctionComponent<ComponentProps>,
  },
  {
    value: PdfProductType.BAMBORA_ONE_SHORT_TERM,
    text: "Worldline One Short Term",
    component: WorldlineOneShortTerm as FunctionComponent<ComponentProps>,
  },
  {
    value: PdfProductType.BAMBORA_DEVICE,
    text: "Worldline Device",
    component: WorldlineDevice as FunctionComponent<ComponentProps>,
  },
  {
    value: PdfProductType.BAMBORA_DEVICE_SHORT_TERM,
    text: "Worldline Device Short Term",
    component: WorldlineDeviceShortTerm as FunctionComponent<ComponentProps>,
  },
  {
    value: PdfProductType.ACCEPTANCE_INSTORE,
    text: "Acceptance Instore",
    component: AcceptanceInstore as FunctionComponent<ComponentProps>,
  },
  {
    value: PdfProductType.ACCEPTANCE_ONLINE,
    text: "Acceptance Online",
    component: AcceptanceOnline as FunctionComponent<ComponentProps>,
  },
  {
    value: PdfProductType.CHECKOUT,
    text: "Checkout",
    component: Checkout as FunctionComponent<ComponentProps>,
  },
];

const componentOpts: ComponentOption[] = [
  {
    value: PrintSection.COMPANY_INFO,
    text: "Company information",
    component: CompanyInformation as FunctionComponent<ComponentProps>,
  },
  {
    value: PrintSection.PRICING,
    text: "Pricing",
    component: Pricing as FunctionComponent<ComponentProps>,
  },
  // {
  //   value: PrintSection.PRODUCT,
  //   text: "Product",
  //   component: Product as FunctionComponent<ComponentProps>,
  // },
  // {
  //   value: PrintSection.PRE_PAYMENT,
  //   text: "Pre-payments",
  //   component: PrePayments as FunctionComponent<ComponentProps>,
  // },
  // {
  //   value: PrintSection.GIFTCARDS,
  //   text: "Giftcards",
  //   component: Giftcards as FunctionComponent<ComponentProps>,
  // },
  // {
  //   value: PrintSection.SUBSCRIPTIONS,
  //   text: "Subscriptions",
  //   component: Subscriptions as FunctionComponent<ComponentProps>,
  // },

  {
    value: PrintSection.DISCLAIMER,
    text: "Disclaimer",
    component: Disclaimer as FunctionComponent<ComponentProps>,
  },
  {
    value: PrintSection.SIGNATORY,
    text: "Signatory",
    component: Signatory as FunctionComponent<ComponentProps>,
  },
  {
    value: PrintSection.STORE,
    text: "Store",
    component: Store as FunctionComponent<ComponentProps>,
  },
  {
    value: PrintSection.SALES_DATA,
    text: "Sales data",
    component: SalesData as FunctionComponent<ComponentProps>,
  },
  {
    value: PrintSection.CAMPAGIN,
    text: "Campaign",
    component: Campaign as FunctionComponent<ComponentProps>,
  },
];

function getComponent(
  option: Option,
  language: Language,
  config: Config,
  show: boolean,
  individualComponent?: ComponentOption
) {
  if (individualComponent) {
    return individualComponent.component ? (
      <individualComponent.component
        language={language}
        show={show}
        config={config}
        productType={option.value}
      />
    ) : (
      "Missing component"
    );
  }

  return option.component ? (
    <option.component
      language={language}
      show={show}
      config={config}
      productType={option.value}
    />
  ) : (
    "Missing component"
  );
}

export const PdfPage: React.FunctionComponent = () => {
  const { i18n } = useTranslation();
  const [opt, setOpt] = useState<Option>(opts[0]);
  const [individualComponent, setIndividualComponent] =
    useState<ComponentOption>(componentOpts[0]);
  const [showPlaceholders, setShowPlaceholders] = useState<boolean>(true);
  const [showComponent] = useState<boolean>(true);
  const [config, setConfig] = useState<Config>({
    ecomType: EcomType.APP,
    numberOfSignees: 1,
    numberOfStores: 1,
    numberOfCampaigns: 1,
    pageNumber: "1",
    numberOfPages: "2",
  });

  const isEcom =
    opt.value === PdfProductType.ACCEPTANCE_ONLINE ||
    opt.value === PdfProductType.CHECKOUT;

  const hasMultiplePages =
    individualComponent.value === PrintSection.PRICING ||
    individualComponent.value === PrintSection.DISCLAIMER;

  return (
    <Page stripped background>
      <section>
        <article>
          <div className={styles.wrapper}>
            <LanguageSelect />

            <hr className={styles.divider} />
            {/* <RadioGroup
              label="Type of pdf"
              alternatives={[
                {
                  value: "no",
                  text: "Show full application",
                },
                {
                  value: "yes",
                  text: "Show individual application sections",
                },
              ]}
              value={showComponent ? "yes" : "no"}
              onChange={() => {
                setShowComponent((prev) => !prev);
              }}
            /> */}

            <Select
              alternatives={opts}
              value={opt.value}
              onChange={(value) => {
                const current = opts.find((opt) => opt.value === value);
                setOpt(current || opts[0]);
              }}
              label="Select product type"
            />

            {showComponent ? (
              <Select
                alternatives={componentOpts}
                value={individualComponent.value}
                onChange={(value) => {
                  const current = componentOpts.find(
                    (opt) => opt.value === value
                  );
                  setIndividualComponent(current || componentOpts[0]);
                }}
                label="Select individual component"
              />
            ) : null}

            {hasMultiplePages ? (
              <RadioGroup
                label="Number of pages"
                className="horizontal"
                alternatives={[
                  {
                    value: "1",
                    text: "One",
                  },
                  {
                    value: "2",
                    text: "Two",
                  },
                  {
                    value: "3",
                    text: "Three",
                  },
                ]}
                value={config.numberOfPages}
                onChange={(value) => {
                  setConfig((prev) => ({
                    ...prev,
                    numberOfPages: value,
                  }));
                }}
              />
            ) : null}

            {hasMultiplePages ? (
              <RadioGroup
                label="Current page"
                alternatives={[
                  {
                    value: "",
                    text: "No paging",
                  },
                  {
                    value: "1",
                    text: "First page",
                  },
                  {
                    value: "2",
                    text: "Second page",
                  },
                  {
                    value: "3",
                    text: "Third page",
                  },
                ]}
                value={config.pageNumber}
                onChange={(value) => {
                  setConfig((prev) => ({
                    ...prev,
                    pageNumber: value,
                  }));
                }}
              />
            ) : null}

            {isEcom ? (
              <RadioGroup
                label="Type of Ecom"
                className="horizontal"
                alternatives={[
                  {
                    value: EcomType.APP,
                    text: "App",
                  },
                  {
                    value: EcomType.WEB,
                    text: "Website",
                  },
                ]}
                value={config.ecomType}
                onChange={() => {
                  setConfig((prev) => ({
                    ...prev,
                    ecomType:
                      config.ecomType === EcomType.WEB
                        ? EcomType.APP
                        : EcomType.WEB,
                  }));
                }}
              />
            ) : null}

            {individualComponent.value === PrintSection.STORE ? (
              <>
                <Number
                  label="Number of stores"
                  value={config.numberOfStores}
                  onChange={(value) => {
                    setConfig((prev) => ({
                      ...prev,
                      numberOfStores: value,
                    }));
                  }}
                />
              </>
            ) : null}

            {individualComponent.value === PrintSection.CAMPAGIN ? (
              <>
                <Number
                  label="Number of campaigns"
                  value={config.numberOfCampaigns}
                  onChange={(value) => {
                    setConfig((prev) => ({
                      ...prev,
                      numberOfCampaigns: value,
                    }));
                  }}
                />
              </>
            ) : null}

            {individualComponent.value === PrintSection.SIGNATORY ? (
              <>
                <Number
                  label="Number of signess"
                  value={config.numberOfSignees}
                  onChange={(value) => {
                    setConfig((prev) => ({
                      ...prev,
                      numberOfSignees: value,
                    }));
                  }}
                />
              </>
            ) : null}

            <hr className={styles.divider} />

            <Checkboxes
              label="Show placeholder data"
              alternatives={[
                {
                  value: "yes",
                  text: "Yes",
                },
              ]}
              values={showPlaceholders ? ["yes"] : []}
              onChange={() => {
                setShowPlaceholders((prev) => !prev);
              }}
            />
          </div>

          <div className={extStyles.body}>
            {getComponent(
              opt,
              i18n.language as Language,
              config,
              showPlaceholders,
              showComponent ? individualComponent : undefined
            )}
          </div>
        </article>
      </section>
    </Page>
  );
};
