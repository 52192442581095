import React from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getDefaultStyles } from "../PdfStyles";
import { ComponentProps } from "../PdfPage";
import { Cell } from "./Cell";
import { FirstCell } from "./FirstCell";

interface Props extends ComponentProps {
  isFirst?: boolean;
  multiple?: boolean;
}

export function getSpecificStyles(show: boolean) {
  return StyleSheet.create({
    signee: {
      margin: "4px 0 20px 0",
    },
    info: {
      margin: "4px 0 10px 0",
    },
  });
}

export function getLabels(t: any) {
  return {
    headerMultiple: t("Signatories"),
    header: t("Signatory"),
    info: t(
      "This application form along with the conditions and regulations available at www.worldline.com constitutes the entire agreement. The prevailing conditions and regulations are available at www.worldline.com. Signing this agreement will confirm that we have accepted these conditions. We confirm that the information we have provided in this application is complete and correct. We accept credit information can be obtained and that the application may be rejected without further justification."
    ),
    firstName: t("First name"),
    lastName: t("Last name"),
    fullName: t("Name"),
    nationalPersonId: t("National personal Id"),
    date: t("Signing date"),
    method: t("Signing method"),
    ip: t("Ip address"),
    email: t("E-mail"),
  };
}

export const Signees: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(props.show),
    ...getSpecificStyles(props.show),
  };

  if (props.multiple) {
    return (
      <>
        <View style={styles.sectionHeader}>
          <Text>{labels.header}</Text>
        </View>
        {/* <Cells {...props} /> */}
      </>
    );
  }

  return (
    <>
      <View style={styles.sectionHeader}>
        <Text>{labels.header}</Text>
      </View>
      <Text style={styles.info}>{labels.info}</Text>
      {/* <Cells {...props} /> */}
    </>
  );
};

export const Cells: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const labels = getLabels(t);
  const styles = {
    ...getDefaultStyles(props.show),
    ...getSpecificStyles(props.show),
  };

  return (
    <View style={styles.signee}>
      <View style={styles.split}>
        <View style={styles.column}>
          <FirstCell label={labels.fullName} show={props.show}>
            Hannibal Barca
          </FirstCell>
        </View>
        <View style={styles.column}>
          <Cell label={labels.nationalPersonId} show={props.show}>
            198012134657
          </Cell>
        </View>
      </View>
    </View>
  );
};
