import React, { useState, useMemo } from "react";
import styles from "./AddBankgiroAccount.module.scss";
import {
  ContractStatus,
  Validity,
} from "../../../../../data/models/ContractTypes";
import { Country } from "../../../../../i18n";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { ContractId } from "../../../../../data/models/CommonTypes";
import Kontonummer from "kontonummer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form } from "../../../../../components/form/Form";
import { Dynamic } from "../../../../../components/animate/Dynamic";
import { Beacon } from "../../../../../components/beacon/Beacon";
import { dataBank } from "../../../../../data/dataBank";
import { Status } from "../../../../../data/types";
import { TextInput } from "../../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { T } from "../../../../../components/translation/T";
import { WarningBox } from "../../../../../components/boxes/WarningBox";
import { GenericError } from "../../../../../components/boxes/GenericError";
import { BankValidatorMinLength } from "../../../../../components/form/validators/BankValidatorMinLength";
import { BankValidatorMaxLength } from "../../../../../components/form/validators/BankValidatorMaxLength";
import { dataAssociates } from "../../../../../data/dataAssociates";
import { useContract } from "../../../../../hooks/useContract";

interface Props {
  onClose: () => void;
  contractId: ContractId;
}

const MIN_LENGTH_ACCOUNT = 7;
const MAX_LENGTH_ACCOUNT = 10;
const MIN_LENGTH_CLEARING = 4;
const MAX_LENGTH_CLEARING = 5;

function cleanNumber(numberAsString?: string) {
  if (!numberAsString) {
    return "";
  }

  return numberAsString.replace(/\D/g, "");
}

export const AddBankgiroAccount: React.FunctionComponent<Props> = ({
  contractId,
}) => {
  const { t } = useTranslation();
  const contract = useContract();
  const [bankAccount, setBankAccount] = useState<string>("");
  const [clearing, setClearing] = useState<string>("");
  const [info, setInfo] = useState<Kontonummer | null>(null);
  const [hasShowedWarning, setHasShowedWarning] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);

  const showBankName = useMemo(() => {
    return (
      !!info &&
      cleanNumber(clearing).length + cleanNumber(bankAccount).length >=
        MIN_LENGTH_CLEARING + MIN_LENGTH_ACCOUNT
    );
  }, [info, clearing, bankAccount]);

  const {
    mutate: onSave,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => {
      let clearingNumber = cleanNumber(clearing);
      if (clearing.length > 4) {
        clearingNumber = clearingNumber.slice(0, -1);
      }

      return dataBank.verifyAccountByBankgiro(
        contractId,
        clearingNumber + cleanNumber(bankAccount)
      );
    },
    onSuccess: () => {
      setStatus(Status.SUCCESS);

      queryClient.invalidateQueries({
        queryKey: dataBank.getBankAccountKey(contractId),
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: dataAssociates.getAssociatesKey(contractId),
        refetchType: "all",
      });
    },
    onError: () => {
      setStatus(Status.ERROR);

      window.setTimeout(() => {
        setStatus(Status.DEFAULT);
      }, 3000);
    },
  });

  if (contract.country !== Country.SWEDEN) {
    return null;
  }

  if (
    contract.status !== ContractStatus.MERCHANT_SIGNING &&
    contract.status !== ContractStatus.SALES_CONFIRMATION
  ) {
    return null;
  }

  const disabled = isPending || status !== Status.DEFAULT;

  return (
    <Form
      name="bank-account"
      onSubmit={(_, form) => {
        if (!form.isValid) {
          return;
        }

        if (!info?.valid && !hasShowedWarning) {
          setHasShowedWarning(true);
          return;
        }

        reset();
        setStatus(Status.PENDING);

        setTimeout(() => {
          setHasShowedWarning(false);
          onSave();
        }, 300);
      }}
    >
      <h3>
        <T>Add bank account</T>
      </h3>
      <p>
        <Trans t={t}>
          The account will be <u>verified by bankgiro</u>.
        </Trans>
      </p>

      <div className="m-top-50" />
      <p>
        <b>
          <T>Exempel</T>:
        </b>{" "}
        3300 7810251111
      </p>
      <div className="m-top-30">
        <div className="tablet-columns">
          <div>
            <TextInput
              name="clearing"
              label="Clearing number"
              value={clearing}
              disabled={disabled}
              validators={[
                new RequiredValidator("Required"),
                new BankValidatorMinLength(
                  MIN_LENGTH_CLEARING,
                  (
                    <T
                      id={"At least {{min}} digits are required"}
                      options={{
                        min: MIN_LENGTH_CLEARING,
                      }}
                    />
                  )
                ),
                new BankValidatorMaxLength(
                  MAX_LENGTH_CLEARING,
                  (
                    <T
                      id={"Not more than {{max}} digits are allowed"}
                      options={{
                        max: MAX_LENGTH_CLEARING,
                      }}
                    />
                  )
                ),
              ]}
              onChange={(value) => {
                reset();
                setClearing(value);
                setHasShowedWarning(false);
                try {
                  const info = Kontonummer.parse(value, bankAccount || "", {
                    mode: "strict",
                  });
                  setInfo(info);
                } catch (err) {
                  setInfo(null);
                }
              }}
            />
          </div>
          <div>
            <TextInput
              name="bankAccount"
              label="Account number"
              value={bankAccount}
              disabled={disabled}
              validators={[
                new RequiredValidator("Required"),
                new BankValidatorMinLength(
                  MIN_LENGTH_ACCOUNT,
                  (
                    <T
                      id={"At least {{min}} digits are required"}
                      options={{
                        min: MIN_LENGTH_ACCOUNT,
                      }}
                    />
                  )
                ),
                new BankValidatorMaxLength(
                  MAX_LENGTH_ACCOUNT,
                  (
                    <T
                      id={"Not more than {{max}} digits are allowed"}
                      options={{
                        max: MAX_LENGTH_ACCOUNT,
                      }}
                    />
                  )
                ),
              ]}
              onChange={(value) => {
                reset();
                setBankAccount(value);
                setHasShowedWarning(false);
                try {
                  const info = Kontonummer.parse(clearing, value, {
                    mode: "strict",
                  });
                  setInfo(info);
                } catch (err) {
                  setInfo(null);
                }
              }}
            />
          </div>
        </div>
      </div>

      <Dynamic name={showBankName ? "account" : ""}>
        {info && showBankName ? (
          <div className={styles.info}>
            <Beacon className="mini" validity={Validity.VALID} /> &nbsp;&nbsp;
            <b>{info.bankName}</b> - {`${info.format("pretty")} `}
          </div>
        ) : null}
      </Dynamic>

      <Dynamic name={hasShowedWarning ? "warning" : ""}>
        {hasShowedWarning ? (
          <div className={styles.warning}>
            <WarningBox relative>
              <Trans>
                Bank accounts are sometimes hard to verify. In this case, we are{" "}
                <b>unable to verify</b> the entered account number. Please check
                the account number again. If it looks good, you may proceed.
              </Trans>
            </WarningBox>
          </div>
        ) : null}
      </Dynamic>

      <Dynamic name={isError ? "error" : ""}>
        {isError ? (
          <div className={styles.error}>
            <GenericError />
          </div>
        ) : null}
      </Dynamic>

      <Button block type="submit" status={isPending ? Status.DISABLED : status}>
        <T>Save bank account</T>
      </Button>
    </Form>
  );
};
