import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { GenericError } from "../../components/boxes/GenericError";
import { Button } from "../../components/interactions/Buttons/Button";
import { Spinner } from "../../components/spinner/Spinner";
import { T } from "../../components/translation/T";
import { dataAuth } from "../../data/dataAuth";
import { Status } from "../../data/types";
import { Store, STORE_KEY } from "../../Store";
import { PageStripped } from "../PageStripped";
import { useAtomValue } from "jotai";
import { contractAuthState } from "../../state/contractAuthState";

export const CALLBACK_ROUTE = "/callback";

export function CallbackPage() {
  const [params] = useSearchParams();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const { isPartner } = useAtomValue(contractAuthState);

  useEffect(() => {
    const code = params.get("code");

    if (!code) {
      setStatus(Status.ERROR);
      return;
    }

    dataAuth
      .postCallback(code)
      .then(() => {
        const savedUrl = Store.getValue<string>(
          STORE_KEY.STORE_URL_AT_SESSION_EXPIRED
        );
        window.location.href = savedUrl;
      })
      .catch((err) => setStatus(Status.ERROR));
  }, [params]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);

    setTimeout(() => {
      dataAuth
        .startLogin(!!isPartner)
        .then((data) => (window.location.href = data.url))
        .catch((err) => setStatus(Status.ERROR));
    }, 300);
  }, [isPartner]);

  return (
    <PageStripped title="Sign in with SF">
      <section>
        <article>
          <div className="m-top-40 relative">
            <AnimateHeight name={status}>
              <div>
                {status === Status.ERROR ? (
                  <GenericError center>
                    <div>
                      <T>
                        Something went really wrong. We have to do this whole
                        thing again. Sorry.
                      </T>
                      <div className="m-top-30">
                        <Button block onClick={retry}>
                          <T>Retry</T>
                        </Button>
                      </div>
                    </div>
                  </GenericError>
                ) : null}

                {status === Status.PENDING ? (
                  <div className="center">
                    <Spinner />
                    <div className="m-top-20">
                      <T>Signing in ...</T>
                    </div>
                  </div>
                ) : null}
              </div>
            </AnimateHeight>
          </div>
        </article>
      </section>
    </PageStripped>
  );
}
