import { getDefaultStore } from "jotai";
import { API, ServerError } from "../network/API";
import { contractAuthState } from "../state/contractAuthState";

export const UNAUTHORIZED_STATUS_CODE = 401;
export const FORBIDDEN_STATUS_CODE = 403;
export const NOT_FOUND_STATUS_CODE = 404;

function actAndDelagate<T>(err: ServerError<T>) {
  if (
    err.status !== UNAUTHORIZED_STATUS_CODE &&
    err.status !== FORBIDDEN_STATUS_CODE
  ) {
    return Promise.reject(err);
  }

  const store = getDefaultStore();
  const authState = store.get(contractAuthState);

  if (err.status === UNAUTHORIZED_STATUS_CODE) {
    store.set(contractAuthState, {
      ...authState,
      authorized: false,
    });
  }

  if (err.status === FORBIDDEN_STATUS_CODE) {
    store.set(contractAuthState, {
      ...authState,
      permission: false,
    });
  }

  return Promise.reject(err);
}

export const data = {
  get: <T>(url: string, options?: RequestInit) => {
    return API.get<T>(url, options).catch(actAndDelagate);
  },

  post: <T>(url: string, body?: any) => {
    return API.post<T>(url, body).catch(actAndDelagate);
  },

  delete: <T>(url: string, body?: any) => {
    return API.delete<T>(url, body).catch(actAndDelagate);
  },
};
