import React from "react";
import { ScrollPosition } from "../../../components/scrollPosition/ScrollPosition";
import {
  CAS_ERROR_CODE,
  CLAIM_ERROR_CODE,
  SERVER_ERROR_CODE,
} from "../../../data/dataContracts";
import { MultiForm } from "../../../components/form/MultiForm";
import { Contract } from "../../../data/models/ContractTypes";
import { ServerError } from "../../../network/API";
import { ContractEdit } from "./ContractEdit";
import { Page } from "../../Page";
import { useContract } from "../../../hooks/useContract";
import "./ContractPage.scss";

export const CONTRACT_ROUTE = "/contract/:contractId";

export enum ContractSaveError {
  CAS_ERROR,
  CLAIM_ERROR,
  SERVER_ERROR,
  SAVE_ERROR,
}

export function handleError(
  err: ServerError<any>,
  setError: (contractError: ContractSaveError) => void
) {
  if (err.status === CLAIM_ERROR_CODE) {
    setError(ContractSaveError.CLAIM_ERROR);
    return;
  }

  if (err.status === CAS_ERROR_CODE) {
    setError(ContractSaveError.CAS_ERROR);
    return;
  }

  if (err.status === SERVER_ERROR_CODE) {
    setError(ContractSaveError.SERVER_ERROR);
    return;
  }

  setError(ContractSaveError.SAVE_ERROR);
}

const CreateContractPageInner: React.FunctionComponent<{
  contract?: Contract;
}> = ({ contract }) => {
  if (!contract) {
    return null;
  }

  return <ContractEdit />;
};

export const ContractPage: React.FunctionComponent = () => {
  const contract = useContract();

  return (
    <MultiForm>
      <ScrollPosition>
        <Page title="Contract" className="contract-page" withSectionMenu>
          <section>
            <article>
              <CreateContractPageInner contract={contract} />
            </article>
          </section>
        </Page>
      </ScrollPosition>
    </MultiForm>
  );
};
