import { ReactNode } from "react";
import { atom } from "jotai";

export interface SaveState {
  message?: string | ReactNode;
  date: Date;
}

export const contractSaveState = atom<SaveState[]>([]);

export const contractErrorState = atom<SaveState[]>([]);
