import { Page } from "../../Page";
import "./CompletedPage.module.scss";

export const COMPLETED_ROUTE = "/completed/:contractId";

// const options: DateTimeFormatOptions = {
//   year: "numeric",
//   month: "numeric",
//   day: "numeric",
//   hour: "numeric",
//   minute: "numeric",
// };

// const CompletedContractInner: React.FunctionComponent<{
//   contract?: Contract;
//   id?: ContractId;
// }> = ({ contract, id }) => {
//   const [status, setStatus] = useState<Status>(Status.PENDING);

//   const timer = useRef<number>();
//   const [completedContract, setCompletedContract] =
//     useState<CompletedContract>();

//   const [resetMerchantEmail, setResetmerchantEmail] = useState<Status>(
//     Status.DISABLED
//   );

//   const [resetBackofficeEmail, setResetBackofficeEmail] = useState<Status>(
//     Status.DISABLED
//   );

//   const setContractStatus = useSetAtom(contractStatusState);

//   useEffect(() => {
//     return () => {
//       window.clearTimeout(timer.current);
//     };
//   }, []);

//   const load = useCallback(
//     (id: ContractId) => {
//       setStatus(Status.PENDING);
//       dataContracts.loadContract(id).then((contractResponse) => {
//         setContract(contractResponse);
//       });

//       dataContracts
//         .loadCompletedContract(id)
//         .then((response) => {
//           setCompletedContract(response);
//           setStatus(Status.SUCCESS);
//           if (response.merchantEmailSent) {
//             setResetmerchantEmail(Status.DEFAULT);
//           } else {
//             setResetmerchantEmail(Status.DISABLED);
//           }
//           if (response.backofficeEmailSent) {
//             setResetBackofficeEmail(Status.DEFAULT);
//           } else {
//             setResetBackofficeEmail(Status.DISABLED);
//           }
//           if (isCompletedContractDoneWorking(response, contract)) {
//             return;
//           }
//           timer.current = window.setTimeout(() => load(id), 20000);
//         })
//         .catch(() => {
//           setStatus(Status.ERROR);
//           timer.current = window.setTimeout(() => load(id), 30000);
//         });
//     },
//     [contract, setContractStatus]
//   );

//   useEffect(() => {
//     load(id as ContractId);
//     return () => {
//       if (timer.current) {
//         clearInterval(timer.current);
//       }
//     };
//   }, [load, id]);

//   if (!contract) {
//     return null;
//   }

//   if (!id) {
//     return null;
//   }

//   if (contract.status !== ContractStatus.COMPLETE) {
//     return (
//       <>
//         <h1>{contract.contractData.companyName}</h1>
//         <p>Contract is not marked as completed</p>
//         <Link link={generatePath(REVIEW_ROUTE, { id: contract.contractId })}>
//           Back to timeline
//         </Link>
//       </>
//     );
//   }

//   return (
//     <>
//       <h1>{contract.contractData.companyName}</h1>
//       <StatusBoxes status={status}>
//         <ErrorBox relative>
//           Something went wrong. We couldn't fetch the most recent data. Reload
//           page?
//         </ErrorBox>
//       </StatusBoxes>
//       <h5>Status</h5>
//       <dl className="m-bottom-40">
//         <dt className="text-small">
//           {getIntlDate(completedContract?.started, options)}
//         </dt>
//         <dd>
//           Contract was finalized by{" "}
//           <b>{completedContract?.startedBy ?? "..."}</b>.
//         </dd>
//         <dt className="text-small">
//           {getIntlDate(completedContract?.backofficeEmailSent, options)}
//         </dt>
//         <dd>
//           Contract was sent to onboarding team at{" "}
//           <b>{completedContract?.backofficeRecipient ?? "..."}</b>
//         </dd>
//         <dt className="text-small">
//           {getIntlDate(completedContract?.merchantEmailSent, options)}
//         </dt>
//         <dd>
//           Contract was sent to the merchant
//           {completedContract?.merchantId ? (
//             <>
//               {" "}
//               Merchant id: <b>{completedContract.merchantId}</b>
//             </>
//           ) : null}
//           .
//         </dd>

//         <dt className="text-small">
//           {getIntlDate(completedContract?.backofficeEmailViewed, options)}
//         </dt>
//         <dd>Onboarding team viewed contract </dd>

//         <dt className="text-small">
//           {getIntlDate(
//             completedContract?.backofficeAttachmentDownloaded,
//             options
//           )}
//         </dt>
//         <dd>Onboarding team downloaded contract attachments </dd>

//         <dt className="text-small">
//           {getIntlDate(
//             completedContract?.backofficeProvisioningCompleted,
//             options
//           )}
//         </dt>
//         <dd>Onboarding team completed the contract</dd>
//       </dl>
//       <h5>ContractDownloads</h5>
//       <Link
//         className="as-button ghost m-bottom-20"
//         external
//         download={
//           "Contract Bundle " +
//           contract.contractData.companyName +
//           " " +
//           contract.contractData.companyRegistrationId +
//           ".zip"
//         }
//         link={`${HOST}/api/sales/contracts/completed/${contract.contractId}/merchant/contract/zip`}
//       >
//         All Contract Files
//       </Link>{" "}
//       <ul className="m-bottom-40">
//         <li>
//           <Link
//             external
//             download={
//               "Merchant Contract " +
//               contract.contractData.companyName +
//               " " +
//               contract.contractData.companyRegistrationId +
//               ".pdf"
//             }
//             link={`${HOST}/api/sales/contracts/completed/${contract.contractId}/merchant/contract`}
//           >
//             Merchant contract
//           </Link>{" "}
//           (pdf)
//         </li>
//         <li>
//           <Link
//             external
//             download={
//               "Contract Attachments " +
//               contract.contractData.companyName +
//               " " +
//               contract.contractData.companyRegistrationId +
//               ".pdf"
//             }
//             link={`${HOST}/api/sales/contracts/completed/${contract.contractId}/backoffice/attachments`}
//           >
//             Contract attachments
//           </Link>{" "}
//           (pdf)
//         </li>
//       </ul>
//       <h5>Onboarding Downloads</h5>
//       <ul className="m-bottom-20">
//         <li>
//           <Link
//             external
//             download={
//               "Contract Unlocked " +
//               contract.contractData.companyName +
//               " " +
//               contract.contractData.companyRegistrationId +
//               ".pdf"
//             }
//             link={`${HOST}/api/sales/contracts/completed/${contract.contractId}/backoffice/contract`}
//           >
//             Unlocked Merchant Contract
//           </Link>{" "}
//           (pdf)
//         </li>

//         <li>
//           <Link
//             external
//             download={
//               "PASS Import " +
//               contract.contractData.companyName +
//               " " +
//               contract.contractData.companyRegistrationId +
//               ".xlsx"
//             }
//             link={`${HOST}/api/sales/contracts/completed/${contract.contractId}/backoffice/ipass`}
//           >
//             iPass Import
//           </Link>{" "}
//           (xlsx)
//         </li>
//         <li>
//           <Link
//             external
//             download={
//               "Dependant Partner " +
//               contract.contractData.companyName +
//               " " +
//               contract.contractData.companyRegistrationId +
//               ".xlsx"
//             }
//             link={`${HOST}/api/sales/contracts/completed/${contract.contractId}/backoffice/dependant_partner`}
//           >
//             Dependant Partner Import
//           </Link>{" "}
//           (xlsx)
//         </li>
//         {/* TODO
//         <Visible
//           show={contract.contractPackage.packageId !== PackageId.SOFTPOS_BUNDLE}
//         >
//           <li>
//             <Link
//               external
//               download={
//                 "Order " +
//                 contract.contractData.companyName +
//                 " " +
//                 contract.contractData.companyRegistrationId +
//                 ".xlsx"
//               }
//               link={`${HOST}/api/sales/contracts/completed/${contract.contractId}/backoffice/terminal`}
//             >
//               Terminals
//             </Link>{" "}
//             (xlsx)
//           </li>
//         </Visible> */}
//       </ul>
//       <h3>Resend email</h3>
//       <p>
//         <Button
//           ghost
//           onClick={() => {
//             setResetmerchantEmail(Status.PENDING);
//             if (resetBackofficeEmail === Status.DEFAULT) {
//               setResetBackofficeEmail(Status.PENDING);
//             }
//             dataContracts
//               .resendMerchantEmail(contract.contractId)
//               .then(() => {
//                 load(id);
//               })
//               .finally(() => {
//                 setResetmerchantEmail(Status.DEFAULT);
//                 if (resetBackofficeEmail === Status.PENDING) {
//                   setResetBackofficeEmail(Status.DEFAULT);
//                 }
//               });
//           }}
//           status={resetMerchantEmail}
//         >
//           Resend Merchant Email
//         </Button>
//         <Button
//           ghost
//           onClick={() => {
//             setResetmerchantEmail(Status.PENDING);
//             setResetBackofficeEmail(Status.PENDING);
//             dataContracts
//               .resendBackofficeEmail(contract.contractId)
//               .then(() => {
//                 load(id);
//               })
//               .finally(() => {
//                 setResetmerchantEmail(Status.DEFAULT);
//                 setResetBackofficeEmail(Status.DEFAULT);
//               });
//           }}
//           status={resetBackofficeEmail}
//         >
//           Resend Onboarding Email
//         </Button>
//       </p>
//     </>
//   );
// };

export const CompletedPage = () => {
  return <Page>completed page</Page>;
  // const { id } = useParams<{ id: ContractId }>();
  // const [status, setStatus] = useState<Status>(Status.DEFAULT);
  // const [contract, setContract] = useState<Contract>();

  // const copyTimer = useRef<number>();
  // const [copyStatus, setCopyStatus] = useState<Status>(Status.DEFAULT);
  // const [missingPermission, setMissingPermission] = useState(false);

  // const load = useCallback((id: ContractId) => {
  //   dataContracts
  //     .loadContract(id)
  //     .then((response) => {
  //       setContract(response);
  //       setStatus(Status.SUCCESS);
  //     })
  //     .catch((error: ServerError<unknown>) => {
  //       if (error.status === 403) {
  //         setMissingPermission(true);
  //       }
  //       setStatus(Status.ERROR);
  //     });
  // }, []);

  // useEffect(() => {
  //   if (!id) {
  //     return;
  //   }

  //   load(id);
  //   return () => {
  //     if (copyTimer) {
  //       window.clearTimeout(copyTimer.current);
  //     }
  //   };
  // }, [load, id]);

  // const onCopy = useCallback(() => {
  //   window.clearTimeout(copyTimer.current);
  //   navigator.clipboard
  //     .writeText(window.location.toString())
  //     .then(() => {
  //       setCopyStatus(Status.SUCCESS);

  //       copyTimer.current = window.setTimeout(() => {
  //         setCopyStatus(Status.DEFAULT);
  //       }, 2000);
  //     })
  //     .catch((err) => {
  //       setCopyStatus(Status.ERROR);

  //       copyTimer.current = window.setTimeout(() => {
  //         setCopyStatus(Status.DEFAULT);
  //       }, 2000);
  //     });
  // }, []);

  // const retry = useCallback(() => {
  //   if (!id) {
  //     return;
  //   }

  //   setStatus(Status.PENDING);
  //   setTimeout(() => {
  //     load(id);
  //   }, 300);
  // }, [load, id]);

  // if (!id) {
  //   return (
  //     <div>
  //       <p>Invalid link. Missing id in url.</p>
  //     </div>
  //   );
  // }

  // return (
  //   <ContractPage
  //     title="Completed contract"
  //     className="completed-contracts-page"
  //     contractId={id}
  //     menuChildren={
  //       <div className="copy-link">
  //         <Button className="" block ghost onClick={onCopy} status={copyStatus}>
  //           Copy contract link
  //         </Button>
  //       </div>
  //     }
  //   >
  //     <section>
  //       <article>
  //         {missingPermission ? (
  //           <PermissionError />
  //         ) : (
  //           <Retry retry={retry} status={status}>
  //             <CompletedContractInner contract={contract} id={id} />
  //           </Retry>
  //         )}
  //       </article>
  //     </section>
  //   </ContractPage>
  // );
};
