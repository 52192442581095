import styles from "./Offering.module.scss";
import { SectionFieldSet } from "../../../../components/sectionFieldSet/SectionFieldSet";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { PricePlanTable } from "../../../../components/pricePlanTable/PricePlanTable";
import { FunctionComponent } from "react";
import { Products } from "./products/Products";
import { FormName } from "../menus/ContractEditMenu";
import { LinkAnchors } from "../ContractEdit";
import { Form } from "../../../../components/form/Form";
import { T } from "../../../../components/translation/T";
import { dataPricing } from "../../../../data/dataPricing";
import { useContractId } from "../../../../hooks/useContractId";
import { useTranslation } from "react-i18next";
import { Language } from "../../../../i18n";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useContract } from "../../../../hooks/useContract";

export const Offering: FunctionComponent = () => {
  return (
    <SectionFieldSet
      headerTitle={LinkAnchors.OFFERING.name}
      formName={FormName.OFFERING}
      sectionNumber={5}
    >
      <OfferingInner />
    </SectionFieldSet>
  );
};

const OfferingInner: FunctionComponent = () => {
  const { i18n } = useTranslation();
  const contract = useContract();
  const contractId = useContractId();
  const { productType } = contract;

  const { data: pricePlan } = useSuspenseQuery(
    dataPricing.fetchPricing(contractId)
  );

  return (
    <div>
      <ScrollPositionAnchor id={LinkAnchors.OFFERING.anchor} />
      <Form>
        <div className={styles.content}>
          <Products selectedProductType={productType} />
          <div className="m-top-40">
            <div className={styles.sectionTitle}>
              <T>Price and Terms of Service</T>
            </div>
            <PricePlanTable
              pricePlan={pricePlan}
              language={i18n.language as Language}
              contract={contract}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
