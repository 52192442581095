import { data } from "./data";
import { ContractId, UTCDate } from "./models/CommonTypes";
import { EcomType, ProductType } from "./models/ContractTypes";

interface LoginResponse {
  url: string;
}

export interface SessionResponse {
  expiry: UTCDate;
  name: string;
}

export enum PrintSection {
  COMPANY_INFO = "company-information",
  PRICING = "pricing",
  DISCLAIMER = "disclaimer",
  PRODUCT = "product",
  PRE_PAYMENT = "prepayment",
  GIFTCARDS = "giftcards",
  SUBSCRIPTIONS = "subscriptions",
  STORE = "store",
  SIGNATORY = "signatory",
  SALES_DATA = "sales-data",
  CAMPAGIN = "campaign",
}

export interface PrintConfig {
  productType: ProductType;
  printSection: PrintSection;
  ecomType: EcomType;
}

export const dataAdmin = {
  print: (config: PrintConfig) => data.post<LoginResponse>("/api/pdf", config),

  postSkipSigning: (contractId: ContractId) =>
    data.post(`/api/admin/skip-signing/${contractId}`),

  postCallback: (code: string) =>
    data.post("/api/login/callback", {
      code,
    }),

  getCurrentSession: () => data.get<SessionResponse>("/api/whoami"),
};
