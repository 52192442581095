import cx from "classnames";
import { Settings } from "luxon";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteListener } from "./components/RouteListener";
import { NotFoundPage } from "./pages/NotFoundPage";
import {
  ContractPage,
  CONTRACT_ROUTE,
} from "./pages/sales/Contract/ContractPage";
import {
  AssociateReviewPage,
  ASSOCIATE_REVIEW_PAGE,
} from "./pages/sales/Review/AssociateReview/AssociateReviewPage";
import { ReviewPage, REVIEW_ROUTE } from "./pages/sales/Review/ReviewPage";
import { PersistSettings } from "./PersistSettings";
import { themeState, viewState } from "./state/uiState";
import i18n from "./i18n";
import { TranslationManagement } from "./components/translation/TranslationManagement";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  CONTRACT_AUDIT_PAGE,
  ContractAuditPage,
} from "./pages/manager/audit/AdminContractAuditLog";
import {
  COMMUNICATION_PAGE_URL,
  CommunicationPage,
} from "./pages/manager/communication/communicationPage/CommunicationPage";
import {
  EMAIL_PREVIEW_URL,
  EmailPreview,
} from "./pages/manager/communication/emailPreview/EmailPreview";
import {
  TEXT_PREVIEW_URL,
  TextPreview,
} from "./pages/manager/communication/textPreview/TextPreview";
import { MCC_EDIT_PAGE, EditMccPage } from "./pages/manager/mcc/EditMccPage";
import {
  MCC_ADMIN_PAGE,
  MccAdministrationPage,
} from "./pages/manager/mcc/MccAdministrationPage";
import {
  TRANSLATIONS_PAGE,
  TranslationsPage,
} from "./pages/manager/translation/TranslationPage";
import { RoutingPage, ROUTING_ROUTE } from "./pages/login/RoutingPage";
import { CALLBACK_ROUTE, CallbackPage } from "./pages/login/CallbackPage";
import { AuthOverlay, PermissionOverlay } from "./pages/Page";
import { contractAuthState } from "./state/contractAuthState";
import { PDF_PAGE_URL, PdfPage } from "./pages/manager/pdfContract/PdfPage";
import {
  SUPPLEMENTS_ROUTE,
  SupplementsPage,
} from "./pages/sales/Supplements/SupplementsPage";
import {
  COMPLETED_ROUTE,
  CompletedPage,
} from "./pages/sales/Completed/CompletedPage";
import { ContractsPage } from "./pages/sales/Contracts/ContractsPage";
import {
  REPROVISIONING_SALESFORCE_ROUTE,
  ReProvisionSalesforcePage,
} from "./pages/sales/ReProvisionSalesforcePage/ReProvisionSalesforcePage";
import { useAtomValue } from "jotai";
import { ErrorBoundary } from "./components/errors/ErrorBoundary";
import { ServerError } from "./network/API";
import { isDev } from "./pages/sales/Review/Review";
import { Contract, ContractStatus } from "./data/models/ContractTypes";
import { FORBIDDEN_STATUS_CODE, UNAUTHORIZED_STATUS_CODE } from "./data/data";

Settings.defaultLocale = i18n.language;

const DEFAULT_NUMBER_OF_RETRIES = 4;

export function isContractDisabled(contract: Contract) {
  const urlParams = new URLSearchParams(window.location.search);
  const adminEdit = urlParams.get("adminEdit");
  return adminEdit === "true"
    ? false
    : contract.status !== ContractStatus.SALES_INPUT || contract.readOnly;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: (failureCount, err) => {
        if (
          err &&
          (err as ServerError<any>).status === UNAUTHORIZED_STATUS_CODE
        ) {
          return false; // do not retry, trigger error
        }

        if (err && (err as ServerError<any>).status === FORBIDDEN_STATUS_CODE) {
          return false; // do not retry, trigger error
        }

        return failureCount < DEFAULT_NUMBER_OF_RETRIES - 1;
      },
      retryDelay: isDev()
        ? 500
        : (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const relativeRoute = (route: string) => {
  return route.replace(/^\/manager\//, "");
};

export const MANAGER_BASE_ROUTE = "/manager/*";

function App() {
  const theme = useAtomValue(themeState);
  const view = useAtomValue(viewState);
  const isTranslationManagementActive = false;
  // const [url, setUrl] = useState<string>("");
  const { authorized, permission, isPartner } = useAtomValue(contractAuthState);

  // useEffect(() => {
  //   if (authorized) {
  //     return;
  //   }

  //   Store.setValue(
  //     STORE_KEY.STORE_URL_AT_SESSION_EXPIRED,
  //     window.location.href
  //   );

  //   console.log("app");

  //   dataAuth
  //     .startLogin(!!isPartner)
  //     .then(({ url }) => setUrl(url))
  //     .catch(() => {}); // let it slip, we handle this in AuthOverlay
  // }, [authorized, isPartner]);
  // const { active: isTranslationEditorActive } = useAtomValue(
  //   translationManagementState
  // );

  // const isTranslationManagementActive =
  //   isTranslationEditorActive && user && user.role === Role.MANAGER;

  return (
    <QueryClientProvider client={queryClient}>
      <RouteListener />
      <PersistSettings />
      <div className={theme}>
        <div className={cx("app", view)}>
          <div id="overlay-portal" />
          <AuthOverlay isPartner={!!isPartner} requireAuth={!authorized} />
          <PermissionOverlay missingPermission={!permission} />
          {isTranslationManagementActive && <TranslationManagement />}
          <main>
            <ErrorBoundary>
              <Routes>
                <Route path={CONTRACT_ROUTE} element={<ContractPage />} />

                <Route
                  path={REPROVISIONING_SALESFORCE_ROUTE}
                  element={<ReProvisionSalesforcePage />}
                />

                <Route path={REVIEW_ROUTE} element={<ReviewPage />} />

                <Route
                  path={ASSOCIATE_REVIEW_PAGE}
                  element={<AssociateReviewPage />}
                />

                <Route path={COMPLETED_ROUTE} element={<CompletedPage />} />

                <Route path={SUPPLEMENTS_ROUTE} element={<SupplementsPage />} />

                <Route
                  path={relativeRoute(CONTRACT_AUDIT_PAGE)}
                  element={<ContractAuditPage />}
                />

                <Route
                  path={relativeRoute(MCC_ADMIN_PAGE)}
                  element={<MccAdministrationPage />}
                />

                <Route
                  path={relativeRoute(MCC_EDIT_PAGE)}
                  element={<EditMccPage />}
                />

                <Route
                  path={TRANSLATIONS_PAGE}
                  element={<TranslationsPage />}
                />

                <Route
                  path={relativeRoute(EMAIL_PREVIEW_URL)}
                  element={<EmailPreview />}
                />

                <Route
                  path={relativeRoute(TEXT_PREVIEW_URL)}
                  element={<TextPreview />}
                />

                <Route
                  path={relativeRoute(COMMUNICATION_PAGE_URL)}
                  element={<CommunicationPage />}
                />

                <Route
                  path={relativeRoute(COMMUNICATION_PAGE_URL + "/*")}
                  element={<Navigate to={COMMUNICATION_PAGE_URL} />}
                />

                <Route
                  path={relativeRoute(PDF_PAGE_URL)}
                  element={<PdfPage />}
                />

                <Route path={CALLBACK_ROUTE} element={<CallbackPage />} />

                <Route
                  path={`${CALLBACK_ROUTE}/partner`}
                  element={<CallbackPage />}
                />

                <Route path={ROUTING_ROUTE} element={<RoutingPage />} />

                <Route index element={<ContractsPage />} />

                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </ErrorBoundary>
          </main>
        </div>
      </div>
    </QueryClientProvider>
  );
}

export default App;
