import React, { useState, useCallback, useMemo } from "react";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { AnimateHeight } from "../../../../../components/animate/AnimateHeight";
import { GenericError } from "../../../../../components/boxes/GenericError";
import { SuccessBox } from "../../../../../components/boxes/SuccessBox";
import { Contact } from "../../../../../components/contact/Contact";
import { hasRealErrors } from "../../../../../components/form/FormHelpers";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { Or } from "../../../../../components/or/Or";
import { T } from "../../../../../components/translation/T";
import {
  dataAssociates,
  getRoleAsText,
} from "../../../../../data/dataAssociates";
import { dataBank } from "../../../../../data/dataBank";
import {
  AssociateRole,
  Associate,
} from "../../../../../data/models/AssociateTypes";
import { Contact as ContactInterface } from "../../../../../data/models/ContractTypes";
import { associateQueue } from "../../../../../data/queues/AssociateQueue";
import { Status } from "../../../../../data/types";
import { Form } from "../../../../../components/form/Form";
import { NewOverlay } from "../../../../../components/overlay/NewOverlay";
import { useContractId } from "../../../../../hooks/useContractId";
import { Name } from "../../../../../components/name/Name";
import { useTranslation } from "react-i18next";
import { defaultAssociate } from "../../../../../components/associateUtils";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const bogusAssociate = {
  contact: {},
  roles: [],
};

function getRoles(associate: Associate, t: any) {
  const roles = associate.roles.filter(
    (role) =>
      role === AssociateRole.BENEFICIAL_OWNER ||
      role === AssociateRole.SELECTED_SIGNATORY
  );

  if (roles.length) {
    return `- ${roles.map((role) => t(getRoleAsText(role))).join(", ")}`;
  }
  return "";
}

export const AccountHolder: React.FunctionComponent<Props> = ({
  open,
  setOpen,
}) => {
  const { t } = useTranslation();
  const contractId = useContractId();
  const { data: associates } = useSuspenseQuery(
    dataAssociates.fetchAssociates(contractId)
  );
  const [notifyStatus, setNotifyStatus] = useState<Status>(Status.DEFAULT);
  const [addStatus, setAddStatus] = useState<Status>(Status.DEFAULT);
  const [contact, setContact] = useState<ContactInterface>({});
  const currentHolder = useMemo(
    () =>
      associates.find((item) =>
        item.roles.includes(AssociateRole.ACCOUNT_HOLDER)
      ),
    [associates]
  );
  const queryClient = useQueryClient();

  const notify = useCallback(() => {
    setNotifyStatus(Status.PENDING);
    setAddStatus(Status.DISABLED);
    dataBank
      .remindAccountHolder(contractId)
      .then(() => {
        setNotifyStatus(Status.SUCCESS);
        queryClient.invalidateQueries({
          queryKey: dataBank.getBankAccountKey(contractId),
        });
      })
      .catch(() => {
        setNotifyStatus(Status.ERROR);
        setTimeout(() => {
          setAddStatus(Status.DEFAULT);
          setNotifyStatus(Status.DEFAULT);
        }, 3000);
      });
  }, [queryClient, contractId]);

  const onAdd = useCallback(() => {
    if (!currentHolder) {
      return;
    }

    setNotifyStatus(Status.DISABLED);
    setAddStatus(Status.PENDING);

    associateQueue
      .saveAssociate(contractId, {
        ...currentHolder,
        roles: currentHolder?.roles.filter(
          (role) => role !== AssociateRole.ACCOUNT_HOLDER
        ),
      })
      .then(() => {
        return associateQueue
          .saveAssociate(contractId, {
            ...defaultAssociate,
            contact,
            roles: [AssociateRole.ACCOUNT_HOLDER],
            id: {},
            pep: {},
            address: {
              street: "",
              postalCode: "",
              city: "",
              countryCode: "",
            },
          })
          .then(() => {
            setAddStatus(Status.SUCCESS);
            queryClient.invalidateQueries({
              queryKey: dataBank.getBankAccountKey(contractId),
            });
            queryClient.invalidateQueries({
              queryKey: dataAssociates.getAssociatesKey(contractId),
            });
          });
      })
      .catch((err) => {
        setAddStatus(Status.ERROR);
        setTimeout(() => {
          setNotifyStatus(Status.DEFAULT);
          setAddStatus(Status.DEFAULT);
        }, 3000);
      });
  }, [contact, queryClient, contractId, currentHolder]);

  const onChange = useCallback((associate: Associate) => {
    setContact({
      ...associate.contact,
    });
  }, []);

  return (
    <div>
      <NewOverlay
        open={open}
        onClose={() => setOpen(false)}
        className="overlay-confirm-doc"
        width={800}
      >
        <Form
          name="bankgiro-reject"
          onSubmit={(_, form) => {
            const realErrors = hasRealErrors(form);
            if (!form.isValid) {
              return;
            }
            if (realErrors) {
              return;
            }
            onAdd();
          }}
        >
          <h4>
            <T>Remind or change account verifier</T>
          </h4>

          <Or className="m-top-40 white-bg">
            <div>
              <AnimateHeight name={notifyStatus}>
                <div>
                  {notifyStatus === Status.ERROR && <GenericError />}
                  {notifyStatus === Status.SUCCESS && (
                    <>
                      <SuccessBox relative>
                        <T>
                          We have notified the account verifier in order for
                          him/her to add the bank account.
                        </T>
                      </SuccessBox>
                      <Button
                        block
                        className="m-top-20"
                        ghost
                        onClick={() => setOpen(false)}
                      >
                        <T>Close</T>
                      </Button>
                    </>
                  )}
                  {(notifyStatus === Status.DEFAULT ||
                    notifyStatus === Status.DISABLED) && (
                    <>
                      <T>Current account verifier:</T>{" "}
                      <b>
                        <Name
                          associate={
                            currentHolder ||
                            ({
                              contact: {},
                            } as Associate)
                          }
                        />
                      </b>{" "}
                      <i>
                        {getRoles(
                          currentHolder ||
                            (bogusAssociate as unknown as Associate),
                          t
                        )}
                      </i>
                      <Button
                        className="m-top-20"
                        onClick={notify}
                        block
                        ghost
                        status={notifyStatus}
                      >
                        <T>Remind account verifier</T>
                      </Button>
                    </>
                  )}
                </div>
              </AnimateHeight>
            </div>
            <div>
              <b>
                <T>Add a new account verifier</T>
              </b>

              <div className="tablet-columns m-top-30">
                <Contact
                  onChange={onChange}
                  disabled={addStatus !== Status.DEFAULT}
                  associate={
                    {
                      contact,
                    } as Associate
                  }
                  contactRequiredFields={{
                    email: true,
                    phoneNumber: false,
                    firstName: true,
                    lastName: true,
                  }}
                  showTitle={true}
                />
              </div>

              <AnimateHeight name={addStatus}>
                <div>
                  {addStatus === Status.ERROR && <GenericError />}
                  {(addStatus === Status.DEFAULT ||
                    addStatus === Status.DISABLED) && (
                    <Button
                      type="submit"
                      block
                      ghost
                      className="m-top-10"
                      status={addStatus}
                    >
                      <T>Save new account verifier</T>
                    </Button>
                  )}
                  {addStatus === Status.SUCCESS && (
                    <>
                      <SuccessBox relative>
                        <T>
                          We have saved the new account verifier and will reach
                          out to him/her in order to add a new bank account.
                        </T>
                      </SuccessBox>
                      <Button
                        block
                        className="m-top-20"
                        ghost
                        onClick={() => setOpen(false)}
                      >
                        <T>Close</T>
                      </Button>
                    </>
                  )}
                </div>
              </AnimateHeight>

              <div className="m-top-30" />
            </div>
          </Or>
        </Form>
      </NewOverlay>
    </div>
  );
};
