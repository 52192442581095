import { atom } from "jotai";
import { TranslationText } from "./dataTranslation";

interface TranslationManagementState {
  active: boolean;
  translation?: SelectedTranslation;
  registeredTranslations: RegisteredTranslation[];
  translations: TranslationText[];
}

interface SelectedTranslation {
  selected: string;
  alternatives?: string[];
}

export interface RegisteredTranslation {
  id: string;
  ref: React.RefObject<HTMLSpanElement>;
  highlightFunction: () => void;
}

export const translationManagementState = atom<TranslationManagementState>({
  active: false,
  translation: undefined,
  registeredTranslations: [],
  translations: [],
});

export const translationStateSelector = atom(
  (get) => {
    const { translation } = get(translationManagementState);
    return translation;
  },
  (get, set, newValue) =>
    set(translationManagementState, {
      ...get(translationManagementState),
      translation: isSelectedTranslation(newValue) ? newValue : undefined,
    })
);

export const translationStateActiveSelector = atom(
  (get) => {
    const { active } = get(translationManagementState);
    return active;
  },
  (get, set, newValue) => {
    const state = get(translationManagementState);
    set(translationManagementState, {
      ...state,
      active: typeof newValue === "boolean" ? newValue : state.active,
    });
  }
);

export const RegisteredTranslationsSelector = atom(
  (get) => {
    const { registeredTranslations: availableTranslations } = get(
      translationManagementState
    );
    return availableTranslations;
  },
  (get, set, newValue) => {
    const state = get(translationManagementState);

    if (!Array.isArray(newValue)) {
      return;
    }

    const newTranslations = newValue.filter(
      (translation) =>
        !state.registeredTranslations.find((registeredTranslation) => {
          return registeredTranslation.id === translation.id;
        })
    );

    set(translationManagementState, {
      ...state,
      registeredTranslations: [
        ...state.registeredTranslations,
        ...newTranslations,
      ],
    });
  }
);

export const ResetRegisteredTranslationsSelector = atom(
  () => {
    return;
  },
  (get, set) => {
    const state = get(translationManagementState);

    set(translationManagementState, {
      ...state,
      registeredTranslations: [],
    });
  }
);

export const TranslationsSelector = atom(
  (get) => {
    const { translations } = get(translationManagementState);
    return translations;
  },
  (get, set, newValue) => {
    const state = get(translationManagementState);

    if (!Array.isArray(newValue)) {
      return;
    }

    set(translationManagementState, {
      ...state,
      translations: newValue,
    });
  }
);

const isSelectedTranslation = (
  translation: any
): translation is SelectedTranslation => {
  return translation && "selected" in translation;
};
